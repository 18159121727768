import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Order } from '../models/order.model';
import { Transaction } from '../models/transaction.model';
import { CDCService, IClientSearchParams } from '../services/cdc.service';
import { LoadingService } from '../services/loading.service';
import { MsAuthService } from '../services/ms-auth.service';
import { OrderService } from '../services/order.service';
import { TransactionService } from '../services/transaction.service';

@Injectable({
  providedIn: 'root',
})
export class OrderResolver implements Resolve<Order[] | null> {
  // skipcq
  constructor(
    private _orderService: OrderService,
    private _cdcService: CDCService,
    private _loadingService: LoadingService,
    private _translateService: TranslateService,
    private _msAuthService: MsAuthService,
    private _transactionService: TransactionService
  ) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Order[] | null> {
    const orderId = route.paramMap.get('orderId');
    if (orderId) {
      const orders = this._orderService.orders.filter(
        (val) => val.orderId === orderId || val.saleChId === orderId
      );
      if (orderId && orders.length > 0) {
        if (orders[0].type === "RETAILORDER") {
          this._loadTransactionByOrder(orders[0].saleChId).subscribe();
        } else if (orders[0].type === "RETAILTRANSACTION") {
          this._loadTransactionById(orders[0].transactionId, orders[0].registerId, orders[0].initiatingStore, orders[0].orderDate).subscribe();
        }
        return of(orders);
      }

      this._loadingService.toggleLoading();
      return this._orderService.getOrderById(orderId).pipe(
        mergeMap((orders: Order[]) => {
          const searchQuery: IClientSearchParams = {
            fields: ['data', 'profile'],
            customerId: orders[0].clientId,
          };
          if (this._msAuthService.isLoggedIn()) {
            this._cdcService.clientSearch(searchQuery).subscribe();
            if(orders[0].type === "RETAILORDER") {
              this._loadTransactionByOrder(orders[0].saleChId).subscribe();
            } else if(orders[0].type === "RETAILTRANSACTION") {
              this._loadTransactionById(orders[0].transactionId, orders[0].registerId, orders[0].initiatingStore, orders[0].orderDate).subscribe();
            }
          }
          this._loadingService.stopLoading();
          return of(orders);
        }),
        catchError((e) => {
          this._loadingService.stopLoading();
          console.log(e);
          return of(null);
        })
      );
    } else {
      throw new Error(this._translateService.instant('errors.noCustomerId'));
    }
  }

  private _loadTransactionByOrder(orderId: string): Observable<Transaction[]>{
    return this._transactionService.getTransactionByOrderId(orderId).pipe(
      catchError((e) => {
        console.log(e);
        return of(null);
      })
    )
  }

  private _loadTransactionById(transactionId: string, registerId: string, storeId: string, transactionDate: string): Observable<Transaction[]>{
    return this._transactionService.getTransactionById(transactionId, registerId, storeId, transactionDate.replace(/-/g, '')).pipe(
        catchError((e) => {
        console.log(e);
        return of(null);
      })
    )
  }
}
