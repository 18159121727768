import {
  Component, Input
} from '@angular/core';
import { IFulfillmentStatus } from '../../services/order.service';

@Component({
  selector: 'order-item-history',
  templateUrl: './order-item-history.component.html',
  styleUrls: ['./order-item-history.component.scss'],
})
export class OrderItemHistoryComponent {
  public displayColumns = ['date', 'location', 'user', 'status'];
  // public reasonForRejection = '';
  // public displayToolTip = -1;
  //private _timeoutHandler: ReturnType<typeof setTimeout> | null = null;

  // @ViewChild('tooltip', { read: ElementRef }) tooltip: ElementRef | undefined;

  @Input() dataSource: IFulfillmentStatus[] = [];

  // @HostListener('document:mousedown', ['$event'])
  // onGlobalClick(event: MouseEvent): void {
  //   if (
  //     !this.tooltip?.nativeElement.contains(event.target) &&
  //     this._timeoutHandler === null
  //   )
  //     this.displayToolTip = -1;
  // }

  // public getToolTipText(reason: string): string {
  //   return 'Reason for rejection: \n' + reason;
  // }

  // public mouseDownRejected(
  //   status: string,
  //   reason: string,
  //   index: number
  // ): void {
  //   if (status === 'REJECTED') {
  //     this._timeoutHandler = setTimeout(() => {
  //       this.reasonForRejection = reason;
  //       this.displayToolTip = index;
  //       this._timeoutHandler = null;
  //     }, 300);
  //   }
  // }

  // public mouseUpRejected(): void {
  //   if (this._timeoutHandler) {
  //     clearTimeout(this._timeoutHandler);
  //     this._timeoutHandler = null;
  //   }
  // }

  public getDateTime(timestamp: string): string {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const day = date.getDate().toString().padStart(2, '0');
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12 ? hours % 12 : 12;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return `${month}/${day}/${year}\n${strTime}`;
  }

  public getUserId(userId: string): string {
    if (userId.substring(0, 7) === '9999000') {
      return userId.substring(7);
    } else {
      return userId;
    }
  }
}
