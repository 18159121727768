import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Item } from '../../models/item.model';
import { Order } from '../../models/order.model';
import { Product } from '../../models/product.model';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'order-item-summary',
  templateUrl: './order-item-summary.component.html',
  styleUrls: ['./order-item-summary.component.scss'],
})
export class OrderItemSummaryComponent implements OnInit{
  @Input() item: Item | undefined;
  @Input() order: Order | undefined;
  private _subscriptions = new Subscription();
  private _productData: Product;

  constructor(private _productService:ProductService) {}
  ngOnInit(): void {
    this._subscriptions.add(this._productService.getProductImagesById$({
      productId: this.item?.productId ? this.item?.productId : '',
      banner: this.order?.currency === 'CAD' ? 'Aritzia_CA' : 'Aritzia_US'
    }).subscribe((product: Product) => {
      this._productData = product;
    }));
  }

  public get name(): string {
    if (this._productData && this._productData.name) {
      return this._productData.name;
    }
    if(this.order) {
      return this.item.name;
    } else {
      return '';
    }
  }
  
  public get size(): string {
    if (this._productData && this._productData.size && this._productData.size !== '') {
      return this._productData.size;
    }
    if(this.order) {
      return this.item.size;
    } else {
      return '';
    }
  }

  public get color(): string {
    if (this._productData && this._productData.color && this._productData.color !== '') {
      return this._productData.color;
    }
    if(this.order) {
      return this.item.color;
    } else {
      return '';
    }
  }
}
