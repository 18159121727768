<div class="container" *ngIf="order || transaction">
  <div class="text-wrapper">
    <span>{{ 'orderDetail.originalPrice' | translate }}:&nbsp;</span>
    <span>{{ originalPrice | currency }}&nbsp;{{currency}}</span>
  </div>
  <div class="text-wrapper">
    <span>{{ 'orderDetail.amountDiscounted' | translate }}:&nbsp;</span>
    <span>{{ discount | currency }}&nbsp;{{currency}}</span>
  </div>
  <div class="text-wrapper">
    <span>{{ 'orderDetail.shippingFee' | translate }}:&nbsp;</span>
    <span>{{ shippingFee | currency }}&nbsp;{{currency}}</span>
  </div>
  <div class="text-wrapper dark-text">
    <span>{{ 'orderDetail.subtotal' | translate }}:&nbsp;</span>
    <span>{{ subtotal | currency }}&nbsp;{{currency}}</span>
  </div>
  <div class="text-wrapper text-spacing">
    <span>{{ 'orderDetail.tax' | translate }}:&nbsp;</span>
    <span>{{ tax | currency }}&nbsp;{{currency}}</span>
  </div>

  <div class="text-wrapper dark-text text-spacing">
    <span>{{ 'orderDetail.total' | translate }}:&nbsp;</span>
    <span>{{ total | currency }}&nbsp;{{currency}}</span>
  </div>

  <div *ngIf="order && order.type === 'ECOMMORDER'">
    <div class="payment-method-container" *ngFor="let i of order?.payments">
      <div class="text-wrapper text-spacing">
        <span>{{ 'orderDetail.paymentType' | translate }}:&nbsp;</span>
        <span>{{ i.attributes.paymentType }}</span>
      </div>
      <div class="text-wrapper">
        <span>{{ 'orderDetail.id' | translate }}:&nbsp;</span>
        <span class="id-text">{{ i.attributes.paymentType === "PPAL" ? 
          i.attributes.paypalAttributes.paypalEmail : 
          i.attributes.paymentCardNumber }}</span>
      </div>
      <div class="text-wrapper dark-text">
        <span>{{ 'orderDetail.amount' | translate }}:&nbsp;</span>
        <span>{{ i.attributes.paymentAmount| currency }}&nbsp;{{order.currency}}</span>
      </div>
    </div>
  </div>
  <div *ngIf="(!order && transaction) || (order.type === 'RETAILORDER' || order.type === 'RETAILTRANSACTION')">
    <div class="payment-method-container" *ngFor="let payment of transaction?.payments">
      <div class="text-wrapper text-spacing">
        <span>{{ 'orderDetail.paymentType' | translate }}:&nbsp;</span>
        <span>{{ paymentTypeName(payment) }}</span>
      </div>
      <div 
        *ngIf="!(payment.typeName ==='Cash' ||
          payment.typeName ==='Cash Rounded' ||
          payment.typeName === 'Layaway Payments' ||
          payment.typeName === 'Balancing Remainder')" 
          class="text-wrapper">
        <span>{{ 'orderDetail.id' | translate }}:&nbsp;</span>
        <span class="id-text" *ngIf="payment.typeName !== 'Gift Card' && payment.typeName !=='Merch Return Card' && payment.typeName !== 'Staff Voucher'">{{ creditCardInfo(payment) }}</span>
        <span class="id-text" *ngIf="payment.typeName ==='Gift Card' || payment.typeName ==='Merch Return Card' || payment.typeName === 'Staff Voucher'">{{ payment.referenceId }}</span>
      </div>
      <div class="text-wrapper dark-text">
        <span>{{ cashAmountName(payment) }}:&nbsp;</span>
        <span>{{ payment.amount| currency }}&nbsp;{{transaction.currency}}</span>
      </div>
    </div>
  </div>
</div>
