import { ITransactionEnhancement, ITransactionSaleItem } from "../services/transaction.service";

export class TransactionItem {
  private _lineItem: string;
  private _productId: string;
  private _genericId: string;
  private _colour: string;
  private _size: string;
  private _upc: string;
  private _serialId: string;
  private _quantity: number;
  private _initialPrice: number;
  private _salePrice: number;
  private _name: string;
  private _statusId: string;
  private _status: string;
  private _ecommId: string;
  private _shippingName: string;
  private _shippingPhone: string;
  private _cityName: string;
  private _provinceCode: string;
  private _postalCode: string;
  private _countryCode: string;
  private _shippingStreetAddress: string;
  private _referenceId: string;


  constructor(data: ITransactionSaleItem){
    this._lineItem = data.itemId;
    this._productId = data.productId;
    this._genericId = data.genericId;
    this._colour = data.colorName;
    this._size = data.sizeName;
    this._upc = data.upc;
    this._serialId = data.serialId;
    this._quantity = data.quantity;
    this._initialPrice = data.initialPrice;
    this._salePrice = data.salesPrice;
    this._name = data.productName;
    this._statusId = data.typeCode;
    this._status = this._getStatusCodeName(data.typeCode, data.typeName);
    this._referenceId = data.references.id;
    this._ecommId = data.references.locationId === '8001' ? data.references.id : '';
    this._shippingName = data.enhancements.find((val) => val.key === "NAME" && val.group === "DLV")?.value;
    this._shippingPhone = data.enhancements.find((val) => val.key === "PHONE" && val.group === "DLV")?.value;
    this._cityName = data.enhancements.find((val) => val.key === "CITY" && val.group === "DLV")?.value;
    this._provinceCode = data.enhancements.find((val) => val.key === "REGION" && val.group === "DLV")?.value;
    this._postalCode = data.enhancements.find((val) => val.key === "ZIP_CODE" && val.group === "DLV")?.value;
    this._countryCode = data.enhancements.find((val) => val.key === "COUNTRY" && val.group === "DLV")?.value;
    this._shippingStreetAddress = this._getShippingStreetAddress(data.enhancements);
  }

  public get lineItem(): string {
    return this._lineItem;
  }
  public get productId(): string {
    return this._productId;
  }
  public get genericId(): string {
    return this._genericId;
  }
  public get colour(): string {
    return this._colour;
  }
  public get size(): string {
    return this._size;
  }
  public get upc(): string {
    return this._upc;
  }
  public get quantity(): number {
    return this._quantity;
  }
  public get initialPrice(): number {
    return this._initialPrice;
  }
  public get salePrice(): number {
    return this._salePrice;
  }
  public get name(): string {
    return this._name;
  }
  public get statusId(): string {
    return this._statusId;
  }
  public get status(): string {
    return this._status;
  }
  public get serialId(): string {
    return this._serialId;
  }
  public get ecommId(): string {
    return this._ecommId;
  }
  public get shippingName(): string {
    return this._shippingName? this._shippingName : '';
  }
  public get shippingPhone(): string {
    return this._shippingPhone? this._shippingPhone : '';
  }
  public get cityName(): string {
    return this._cityName? this._cityName : '';
  }
  public get provinceCode(): string {
    return this._provinceCode? this._provinceCode : '';
  }
  public get postalCode(): string {
    return this._postalCode? this._postalCode : '';
  }
  public get countryCode(): string {
    return this._countryCode? this._countryCode : '';
  }
  public get shippingStreetAddress(): string {
    return this._shippingStreetAddress ? this._shippingStreetAddress : '';
  }
  public get referenceId(): string {
    return this._referenceId ? this._referenceId : '';
  }

  private _getShippingStreetAddress(enhancements: ITransactionEnhancement[]): string {
    let line1 = enhancements.find((val) => val.key === "ADDR_LINE1" && val.group === "DLV")?.value;
    let line2 = enhancements.find((val) => val.key === "ADDR_LINE2" && val.group === "DLV")?.value;
    let line3 = enhancements.find((val) => val.key === "ADDR_LINE3" && val.group === "DLV")?.value;
    if (line1 && line1 !== '') {
      if (line2 && line2 !== '' && line3 && line3 !== '')
      return `${line1}, ${line2}, ${line3}`;
      else if (line2 && line2 !== '')
        return `${line1}, ${line2}`;
      else return line1;
    } else {
      return '';
    }
  }

  // Generates the matching string with the en/fr json file for transaction status
  private _getStatusCodeName(typeCode: string, name: string): string {
    if(
      typeCode === '2801' ||
      typeCode === '2802' ||
      typeCode === '2803' ||
      typeCode === '2804' ||
      typeCode === '2902' ||
      typeCode === '2903' ||
      typeCode === '2906' ||
      typeCode === '2907' ||
      typeCode === '2909' ||
      typeCode === '2916' ||
      typeCode === '2917' ||
      typeCode === '2919' ||
      typeCode === '2001' ||
      typeCode === '2002' ||
      typeCode === '2003' ||
      typeCode === '2004' ||
      typeCode === '2006' ||
      typeCode === '2007' ||
      typeCode === '2008' ||
      typeCode === '2009' ||
      typeCode === '2010' ||  
      typeCode === '2602' || 
      typeCode === '2603' ||  
      typeCode === '2605' ||
      typeCode === '2606' ||
      typeCode === '2607' ||
      typeCode === '2608' ||
      typeCode === '2609') {
      return 'transactionItem.'+typeCode;
    } else {
      return name;
    }
  }
}