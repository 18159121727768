import { NgModule } from "@angular/core";
import { RouteReuseStrategy, RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ArticleSearchPageComponent } from "./pages/article-search-page/article-search-page.component";
import { ClientSearchPageComponent } from "./pages/client-search-page/client-search-page.component";
import { OrderDetailPageComponent } from "./pages/order-detail-page/order-detail-page.component";
import { OrderItemSummaryPageComponent } from "./pages/order-item-summary-page/order-item-summary-page.component";
import { OrderSearchPageComponent } from "./pages/order-search-page/order-search-page.component";
import { TransactionDetailPageComponent } from "./pages/transaction-detail-page/transaction-detail-page.component";
import { ArticleResolver } from "./resolvers/article.resolver";
import { OrderSearchResolver } from "./resolvers/order-search.resolver";
import { OrderResolver } from "./resolvers/order.resolver";
import { TransactionResolver } from "./resolvers/transaction.resolver";
import { RouteReuse } from "./shared/route-reuse-strategy";

const routes: Routes = [
  {
    path: "client-search",
    component: ClientSearchPageComponent,
    data:{shouldReuseRoute: true},
    canActivate:[MsalGuard]
  },
  {
    path: "order-search",
    component: OrderSearchPageComponent,
    data: { shouldReuse: true },
    resolve: { orders: OrderSearchResolver },
    canActivate:[MsalGuard]
  },
  {
    path: "order/:orderId",
    component: OrderDetailPageComponent,
    resolve: { order: OrderResolver },
    canActivate:[MsalGuard]
  },
  {
    path: "transaction/:transactionId/:registerId/:storeId/:transactionDate",
    component: TransactionDetailPageComponent,
    resolve: { transactions: TransactionResolver },
    canActivate:[MsalGuard]
  },
  {
    path: "article/:articleId/:storeId/:articleDate",
    component: ArticleSearchPageComponent,
    resolve: { transactions: ArticleResolver },
    canActivate:[MsalGuard]
  },
  {
    path: "transaction/order/:orderId",
    component: TransactionDetailPageComponent,
    resolve: { transactions: TransactionResolver },
    canActivate:[MsalGuard]
  },
  {
    path: "order/:orderId/items/:itemIndex",
    component: OrderItemSummaryPageComponent,
    resolve: { order: OrderResolver },
    canActivate:[MsalGuard]
  },
  { path: "", redirectTo: "client-search", pathMatch: "full" },
  { path: "**", redirectTo: "client-search"  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    initialNavigation: !isIframe ? 'enabledBlocking' : 'enabledNonBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: [
    OrderSearchResolver,
    OrderResolver,
    TransactionResolver,
    { provide: RouteReuseStrategy, useClass: RouteReuse },
  ],
})
export class AppRoutingModule {}
