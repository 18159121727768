import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Availability } from '../models/availability.model';
import { ErrorService } from './error.service';
import { LocationService } from './location.service';

export interface IAvailabilityResponse {
  ifAmdpMarkerHdbActivate?: number;
  data: IVariantAvailability[];
  links: ILinks;
}

export interface ISalesChannelAvailability {
  salesChannelId: string;
  salesChannelProductVisible: boolean;
  salesChannelOrderDelivery: boolean;
  salesChannelOrderPickup: boolean;
}

export interface IAvailability {
  locationType: string;
  locationId: string;
  salesChannelType: string;
  quantity: number;
  distance: number;
  distanceUnit: string;
  backorderQuantity: number;
  backorderDate: string;
  salesChannelAvailability: ISalesChannelAvailability[];
}

export interface IAttributes {
  availability: IAvailability[];
}

export interface IVariantAvailability {
  type: string;
  id: string;
  attributes: IAttributes;
}

export interface ILinks {
  self: string;
}

export interface IAvailabilityPayload {
  type: string;
  id?: string;
  attributes: {
    availabilityProducts: string[];
    locationIds: string[];
    countryCode?: string;
    region?: string;
    city?: string;
    postalCode?: string;
    latitude?: number;
    longitude?: number;
    distance?: number;
    systemId?: string;
    fulfillmentZones?: {
      salesChannelType: string;
      locationId: string;
    }[];
  };
}

export interface IAvailabilityBatchResponse {
  data: [
    {
      type: string;
      id: string;
      attributes: {
        availability: {
          locationType: string;
          locationId: string;
          salesChannelType: string;
          quantity: number;
          distance: number;
          distanceUnit: string;
          backorderQuantity: number;
          backorderDate: string;
          salesChannelAvailability: {
            salesChannelId: string;
            salesChannelProductVisible: boolean;
            salesChannelOrderDelivery: boolean;
            salesChannelOrderPickup: boolean;
          }[];
        }[];
      };
    }
  ];
}

export enum eSalesChannelType {
  ecom = 'ecom',
  retail = 'retail',
}

export enum eLocationId {
  omniCA = 'omni_ca',
  warehousesCA = 'warehouses_ca',
  storesCA = 'stores_ca',
  omniUS = 'omni_us',
  warehousesUS = 'warehouses_us',
  storesUS = 'stores_us',
}

@Injectable({
  providedIn: 'root',
})
export class AvailabilityService {
  private _host = environment.apigee.host;
  private _path = `${this._host}/${environment.availability.servicePath}`;
  private _batchPath = `${this._host}/${environment.availability.servicePathBatch}`;

  constructor(
    private httpClient: HttpClient, // skipcq
    private locationService: LocationService, // skipcq
    private _errorService: ErrorService
  ) {}

  /**
   * A method to get the availability for a sku in a list of stores
   *
   * @param id to get availability for
   * @param storeList List of stores to get availability for
   * @returns An observable of availability for the id in the stores provided
   */
  public availabilityForVariant$(
    id: string,
    storeList: string[]
  ): Observable<Availability[][]> {
    return this.batchAvailabilityRequest(id, storeList);
  }

  /**
   * Internal method to create the request payload for the batch call to the
   * availability service
   *
   * @param id List of variants to get availability for
   * @param storeList List of stores to get availability for
   * @returns A list of availability for each variant in the stores provided
   */
  private batchAvailabilityRequest(id: string, storeList: string[]) {
    const stores = this.locationService.stores.filter((store) =>
      storeList.includes(store.id)
    );

    let fulfillmentZone = <any>{};

    const payload: IAvailabilityPayload = {
      type: 'availabilityRequest',
      attributes: {
        availabilityProducts: [id],
        locationIds: storeList,
        fulfillmentZones: [fulfillmentZone],
      },
    };

    return this.httpClient
      .post<IAvailabilityBatchResponse>(this._batchPath, { data: [payload] })
      .pipe(
        map((result) => {
          if (result.data.length > 0) {
            const mapped = result.data
              .map((entry) => {
                let checkStores = [...stores];
                let mappedPreEdit = entry.attributes.availability
                  .map((val) => {
                    const store = stores.find((s) => s.id === val.locationId);
                    if (store) {
                      checkStores.splice(
                        checkStores.findIndex(
                          (checkStore) => checkStore.id === val.locationId
                        ),
                        1
                      );
                      return new Availability(
                        val.quantity,
                        id,
                        store,
                        val.salesChannelAvailability
                      );
                    }
                    return null;
                  })
                  .filter((val) => val !== null);
                // Sets remaining stores that availibilty api did not return to have 0 quantity that
                checkStores.forEach((checkStore) => {
                  mappedPreEdit.push(
                    new Availability(
                      0,
                      id,
                      checkStore,
                      [
                        {
                          salesChannelId: "ECOM",
                          salesChannelOrderDelivery: false,
                          salesChannelOrderPickup: false,
                          salesChannelProductVisible: false
                        },
                        {
                          salesChannelId: "RETAIL",
                          salesChannelOrderDelivery: false,
                          salesChannelOrderPickup: false,
                          salesChannelProductVisible: false
                        }
                      ]
                    )
                  );
                });
                return mappedPreEdit;
              })
              .filter((val) => val.length > 0);
            return mapped;
          }
          throw new Error(`No availability found for variants`);
        }),
        catchError((e) => {
          this._errorService.showError('errors.availabilityLoad');
          return throwError(e);
        })
      );
  }
}
