<div class="info-container">
  <div class="transaction-info" *ngIf="transaction">
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.transactionId' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.id }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.registerId' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.registerId }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.store' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.locationId + ' - ' + transaction.locationName }}</span>
    </div>
    <div class="text-wrapper text-spacing">
      <span>{{ 'transactionDetail.date' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.transactionDate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.timestamp' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.transactionTimestamp }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.saleAssociateId' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.associateId }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'transactionDetail.cashierId' | translate }}:&nbsp;</span>
      <span class="text">{{ transaction.cashierId }}</span>
    </div>
  </div>
  <order-detail-summary class="transaction-info" [transaction]="transaction"></order-detail-summary>
</div>
<div class="item-container">
  <order-detail-item
    *ngFor="let item of transaction.items; let i = index"
    [transactionItem]="item"
    [transaction]="transaction"
    [index]="i">
  </order-detail-item>
</div>