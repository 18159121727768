<div *ngIf="clientOrder" class="container">
  <client-detail-expansion *ngIf="loggedIn$ | async"
    [clientOrder]="clientOrder"></client-detail-expansion>
  <div *ngIf="clientOrder.order.type !== 'RETAILTRANSACTION'" class="title">{{ 'orderDetail.title' | translate }}</div>
  <order-detail *ngIf="clientOrder.order && clientOrder.order.type !== 'RETAILTRANSACTION'" [order]="clientOrder.order"> </order-detail>
  <div *ngIf="clientOrder.order.type !== 'ECOMMORDER'">
    <div class="title">{{ 'transactionDetail.title' | translate }}</div>
    <div [ngClass]="{'object-separator': !isFirst}" *ngFor="let transaction of transactions; first as isFirst">
      <transaction-detail [transaction]="transaction"></transaction-detail>
    </div>
  </div>
</div>
