<ng-container *ngIf="dataStream$ | async as dataList">
  <div class="title-container">
    <span class="title">{{
      'orderSearch.client' | translate
    }}</span>
    <span class="title">{{
      'orderSearch.order' | translate
    }}</span>
    <span class="title mobile-hidden">{{
      'orderSearch.initiatingLocation' | translate
    }}</span>
    <span class="title mobile-hidden">{{
      'orderSearch.pickupLocation' | translate
    }}</span>
    <div class="mobile-smaller title desktop-hidden">
      <mat-icon>store</mat-icon>
    </div>
    <div class="mobile-smaller title desktop-hidden">
      <mat-icon>shopping_bag</mat-icon>
    </div>
    <span class="title red-text mobile-hidden">{{
      'orderSearch.returnTitle' | translate
    }}</span>
    <span class="mobile-medium title">{{ 'orderSearch.date' | translate }}</span>
  </div>
  <cdk-virtual-scroll-viewport
    class="order-table"
    *ngIf="this.currentArray.length > 0"
    [itemSize]="120"
    (scrolledIndexChange)="nextBatch()">
    <li *cdkVirtualFor="let data of dataList; let index = index;">
      <mat-card
        class="order-container"
        (click)="orderClicked(data.order.orderId)">
        <div class="info-container">
          <div class="info">
            <span class="name text" data-dd-action-name="Client Name" data-dd-privacy="mask">{{ getName(data) }}</span>
            <span class="text" data-dd-action-name="Client Phone" data-dd-privacy="mask">{{ getPhone(data) }}</span>
            <div class="ellipsis-text-box">
              <span class="ellipsis-text" data-dd-action-name="Client Email" data-dd-privacy="mask">{{ getEmail(data) }}</span>
            </div>
          </div>
          <div class="info">
            <span class="text">{{(data.order.isRetail ? 'orderSearch.retail'  : 'orderSearch.ecomm') | translate}}</span>
            <span *ngIf="data.order.orderType" class="text mobile-hidden">
              {{ data.order.orderType | translate }}-{{
                data.order.status | translate
              }}
            </span>
            <span *ngIf="data.order.orderType" class="text desktop-hidden">
              {{
                data.order.status | translate
              }}
            </span>
            <span *ngIf="!data.order.orderType" class="text mobile-hidden">
              {{data.order.transactionType && data.order.transactionType !== '' ? data.order.transactionType : ('orderSearch.transaction' | translate)}}
            </span>
            <span *ngIf="!data.order.orderType" class="text desktop-hidden">
              {{data.order.transactionType && data.order.transactionType !== '' ? data.order.transactionType : ('orderSearch.trx' | translate)}}
            </span>
            <span class="text">{{ orderNumber(data.order) }}</span>
          </div>
          <div class="mobile-smaller info">
            <span class="text">{{data.order.initiatingStore}}</span>
          </div>
          <div class="mobile-smaller info">
            <span class="text">{{data.order.pickupStore}}</span>
          </div>
          <div class="info mobile-hidden">
            <span *ngIf="data.order.hasReturns" class="text red-text">{{'orderSearch.containsReturns' | translate}}</span>
          </div>
          <div class="mobile-medium info">
            <span class="text">{{ data.order.orderDate }}</span>
            <span *ngIf="data.order.hasReturns" class="desktop-hidden text red-text">{{'orderSearch.containsReturns' | translate}}</span>
          </div>
        </div>
      </mat-card>
    </li>
    <div class="spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
<div class="spinner" *ngIf="loading && currentArray.length === 0">
  <mat-spinner></mat-spinner>
</div>
