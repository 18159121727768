import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ArticleService } from '../../services/article.service';
import { ErrorService } from '../../services/error.service';
import { MsAuthService } from '../../services/ms-auth.service';

@Component({
  selector: 'article-search-page',
  templateUrl: './article-search-page.component.html',
  styleUrls: ['./article-search-page.component.scss'],
})
export class ArticleSearchPageComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();

  constructor(
      public articleService: ArticleService,
      private _router: Router,
      private _msAuthService: MsAuthService,
      private _errorService: ErrorService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
        this._msAuthService.loggedIn$.subscribe((loggedIn: boolean) => {
          if (!loggedIn) {
            this._errorService.showError('errors.notLoggedIn');
            this._router.navigateByUrl(`/client-search`);
          }
        })
    );

    if (this.articleService.articleTransactions && this.articleService.articleTransactions.length === 1) {
      const articleTransaction = this.articleService.articleTransactions[0];
      const trnDate = articleTransaction.transactionDate.replace(/-/g,"");
      this._router.navigateByUrl(
        `/transaction/${articleTransaction.id}/${articleTransaction.registerId}/${articleTransaction.locationId}/${trnDate}`
      );
    }
    if (!this.articleService.articleTransactions || this.articleService.articleTransactions.length === 0) {
      this._router.navigateByUrl(`/client-search`);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
