import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Client } from '../models/client.model';
import { ErrorService } from './error.service';
import { MsAuthService } from './ms-auth.service';

export interface IClientSearchParams {
  email?: string;
  firstName?: string;
  lastName?: string;
  styleAdvisor?: string;
  transactionsSince?: string;
  query?: string;
  raw?: string;
  count?: number;
  fields?: string[];
  phoneNumber?: string;
  country?: string;
  ceId?: string;
  sfccId?: string;
  customerId?: string;
  page?: number;
}

export interface ICDCResponse {
  errorCode: number;
  apiVersion: number;
  statusCode: number;
  statusReason: string;
  time: string;
  results: {
    data: ICDCData;
    profile: ICDCProfile;
  }[];
  objectsCount: number;
  totalCount: number;
}

export interface ICDCData {
  addresses: {
    zip: string;
    country?: string;
    firstName?: string;
    lastName?: string;
    city: string;
    address1?: string;
    address2?: string;
    state: string;
    type: string;
    preferred?: boolean;
  }[];
  systemFlags: {
    lastUpdatedBy: string;
    isCeImport: boolean;
  };
  phones: {
    number: string;
    countryCode: string;
    type: string;
    preferred?: boolean;
  }[];
  mappedIDs: {
    ce: string[];
    cdc: string[];
    sfcc: string[];
    xstore: string[];
    apropos: string[];
  };
  customer: {
    isLiteAccount: boolean;
    profileType: string;
    isGuest: boolean;
    hasEcommAccount: boolean;
    createdStore: string;
    phone: {
      number: string;
      countryCode: string;
    };
    discount: unknown[];
    fullName: string;
    location?: {
      country: string;
      city: string;
      state: string;
    };
    styleAdvisor: string[];
    consent: {
      lastTransactionDate: string;
      isEmailable: boolean;
      isCallable: boolean;
      isTextable: boolean;
      isStyleAdvisorEmailable: boolean;
      isStyleAdvisorCallable: boolean;
      isStyleAdvisorTextable: boolean;
    };
    email: string;
  };
}

export interface ICDCProfile {
  lastName: string;
  country: string;
  email: string;
  firstName: string;
}

@Injectable({
  providedIn: 'root',
})
export class CDCService {
  public clients: Client[] = [];
  private _host = `${environment.apigee.host}${environment.cdc.servicePath}`;

  private _clients: BehaviorSubject<Client[] | null> = new BehaviorSubject<
    Client[] | null
  >(null);
  public clients$: Observable<Client[] | null> = this._clients.asObservable();

  constructor(
    private _httpClient: HttpClient,
    private _errorService: ErrorService,
    private _msAuthService: MsAuthService
  ) {}

  public clientSearch(params: IClientSearchParams): Observable<Client[]> {
    const url = `${this._host}/${environment.cdc.uris.accountSearch}`;

    let query = '';
    const fields = params.fields ? params.fields.join(',') : '*';

    params.page = params.page ? params.page : 1;
    params.count = params.count ? params.count : 20;
    const offset = params.count * (params.page - 1);

    if (params.raw) {
      query = params.raw;
    } else if (params.query) {
      query = `SELECT ${fields} FROM accounts WHERE \
      (data.customer.fullName = regex('.*${params.query}.*') \
      OR data.customer.location.country = regex('${params.query}.*') \
      OR data.customer.location.state = regex('${params.query}.*') \
      OR data.customer.location.city = regex('${params.query}.*') \
      OR (data.customer.styleAdvisor.assignedStyleAdvisor = regex('${params.query}.*') \
      OR data.customer.styleAdvisor.assignedStyleAdvisor CONTAINS regex('${params.query}.*')) \
      OR data.customer.email = regex('.*${params.query}.*')) AND \
      (data.customer.location.country is null OR data.customer.location.country in ('CA','US',' ')) \
      OR data.customer.profileType != 'EMPLOYEE' ORDER BY data.customer.fullName LIMIT ${params.count} START ${offset}`;
    } else {
      const conditions = <string[]>[];

      if (params.email) {
        conditions.push(`email like '${params.email.toLowerCase()}'`);
      }

      if (params.firstName) {
        conditions.push(`firstName like '${params.firstName}'`);
      }

      if (params.lastName) {
        conditions.push(`lastName like '${params.lastName}'`);
      }

      if (params.phoneNumber) {
        const formattedPhone = params.phoneNumber
          .replace(/\D/g, '')
          .split('')
          .join('%');
        conditions.push(
          `data.phones.number like '%${formattedPhone}' OR data.customer.phone.number like '%${formattedPhone}'`
        );
      }

      if (params.styleAdvisor) {
        conditions.push(`(data.customer.styleAdvisor.assignedStyleAdvisor="${params.styleAdvisor}" \
        OR data.customer.styleAdvisor.assignedStyleAdvisor CONTAINS "${params.styleAdvisor}")`);
      }

      if (params.transactionsSince) {
        conditions.push(
          `data.customer.consent.lastTransactionDate >= "${params.transactionsSince}"`
        );
      }

      if (params.country) {
        conditions.push(`data.customer.location.country='${params.country}'`);
      }

      if (params.ceId) {
        conditions.push(`data.mappedIDs.ce CONTAINS '${params.ceId}'`);
      }

      if (params.sfccId) {
        conditions.push(`data.mappedIDs.sfcc CONTAINS '${params.sfccId}'`);
      }

      if (params.customerId) {
        conditions.push(
          `data.mappedIDs.ce='${params.customerId}' OR data.mappedIDs.sfcc='${params.customerId}'`
        );
      }

      query = `SELECT ${fields} FROM accounts WHERE (${conditions.join(
        ' AND '
      )}) ORDER BY data.customer.fullName LIMIT ${
        params.count || 20
      } START ${offset}`;
    }

    const queryString = new URLSearchParams({ query });

    return this._httpClient
      .post<ICDCResponse>(`${url}?${queryString.toString()}`, undefined)
      .pipe(
        map((result: ICDCResponse) => {
          this.clients = result.results.map((profile) =>
              this._msAuthService.isLoggedIn()
                  ? new Client(profile.data, profile.profile)
                  : new Client(profile.data)
          );
          this._clients.next(this.clients);
          return this.clients;
        }),
        catchError((e) => {
          this._errorService.showGeneralError(true);
          throw e;
        })
      );
  }

}
