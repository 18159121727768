<div class="header">{{ 'orderItemSummary.itemHistory' | translate }}</div>
<div *ngIf="item" class="container">
  <div class="item-summary">
    <!-- <img class="image" [src]="item.imgUrl" [alt]="item.name" /> -->
    <div class="text-container">
      <span class="item-name">{{ name }}</span>
      <div class="text-wrapper">
        <span>{{ 'orderItemSummary.itemNumber' | translate }}:&nbsp;</span>
        <span class="text">{{ item.id }}</span>
      </div>
      <div class="text-wrapper text-spacing">
        <span>{{ 'orderItemSummary.color' | translate }}:&nbsp;</span>
        <span class="text">{{ color }}</span>
      </div>
      <div class="text-wrapper">
        <span>{{ 'orderItemSummary.size' | translate }}:&nbsp;</span>
        <span class="text">{{ size }}</span>
      </div>
      <div class="text-wrapper">
        <span>{{ 'orderItemSummary.quantity' | translate }}:&nbsp;</span>
        <span class="text">{{ item.quantity }}</span>
      </div>
      <div class="text-wrapper text-spacing">
        <span>{{ 'orderItemSummary.upc' | translate }}:&nbsp;</span>
        <span class="text">{{ item.upc }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="order.isDeliveryOrder" class="text-wrapper tracking-number">
    <span class="text">
      {{ 'orderItemSummary.trackingNumber' | translate }}:&nbsp;
    </span>
    <span>{{ item.trackingNumbers.join(', ') }}</span>
  </div>
</div>
