import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';
import { IDataPair } from '../models/data-pair.model';
import { IDictionary } from '../models/dictionary.model';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnChanges {
  @ViewChild('selector') selector: MatSelect;
  @Input() public label = '';
  @Input() public enableFilter = false;
  @Input() public multiData: IDictionary = {};
  @Input() public data: IDataPair[] = [];
  @Input() public formControl: UntypedFormControl = new UntypedFormControl([]);
  @Input() public defaultAll = false;
  @Input() public multiple = true;
  @Input() public selectorWidth = '100px';
  @Input() public filterText = this._translateSerivce.instant('shared.dropDown.filter');
  @Output() clickEmitter = new EventEmitter();
  public filteredData: IDataPair[] = [];
  public allSelected = false;
  public multiSelected: string[] = [];
  public filterInput = new FormControl('');

  constructor(private _translateSerivce: TranslateService) {}

  public ngOnChanges(): void {
    this.allSelected = false;
    this.multiSelected = [];
    if (this.defaultAll) {
      this.allSelected = true;
      this.toggleAllSelection();
    }
  }

  public ngAfterViewInit(): void {
    //To prevent weird jumping around action
    (<any>this.selector).baseonselect = (<any>this.selector)._onSelect;
    (<any>this.selector)._onSelect = (ev: any, isUserInput: any) => {
      (<any>this.selector).baseonselect(ev, false);
    };
  }

  public toggleSelection(key: string): void {
    this.clickEmitter.emit();
    if (this.allSelected) {
      this.allSelected = false;
    }
    if (
      this.multiKeys.length === 0 &&
      this.formControl.value.length === this.data.length
    )
      this.allSelected = true;

    // If multidata is being used
    if (this.multiKeys.length > 0) {
      // Find if the box was checked or not
      const index = this.formControl.value.findIndex(
        (val: IDataPair) => val.key === key
      );
      if (index > -1) {
        //box was checked
        this.multiKeys.some((multiKey) => {
          // Find which key in multiData was selected
          const dataIndex = this.multiData[multiKey].findIndex(
            (val: IDataPair) => val.key === key
          );
          if (dataIndex > -1) {
            this.multiData[multiKey][dataIndex].selected = true;
            //Check if all the data inside of multiData[key] is selected
            if (
              !this.multiData[multiKey].some((val: IDataPair) => !val.selected)
            ) {
              this.multiSelected.push(multiKey);
            }
            return true;
          } else {
            return false;
          }
        });
      } else {
        // box unchecked
        this.multiKeys.some((multiKey) => {
          // Find which key in multiData was unselected
          const dataIndex = this.multiData[multiKey].findIndex(
            (val: IDataPair) => val.key === key
          );
          if (dataIndex > -1) {
            this.multiData[multiKey][dataIndex].selected = false;
            const mutliIndex = this.multiSelected.findIndex(
              (val: string) => val === multiKey
            );
            if (this.multiSelected.indexOf(multiKey) > -1)
              // remove from multiSelected
              this.multiSelected.splice(mutliIndex, 1);
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.multiKeys.length === this.multiSelected.length)
        this.allSelected = true;
    }
    this.formControl.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public toggleAllSelection(): void {
    // If multidata is being used
    if (this.multiKeys.length > 0) {
      if (this.allSelected) {
        // Set all data pair as selected
        this.multiKeys.forEach((key) => {
          this.multiData[key] = this.multiData[key].map((data: IDataPair) => {
            data.selected = true;
            return data;
          });
        });

        // Updates the form
        const allStores: IDataPair[] = [];
        this.multiKeys.forEach((key: string) => {
          this.multiData[key].forEach((data: IDataPair) =>
            allStores.push(data)
          );
        });
        this.formControl.patchValue(allStores);

        this.multiSelected = this.multiKeys;
      } else {
        this.formControl.setValue([]);
        this.multiSelected = [];
        this.multiKeys.forEach((key) => {
          this.multiData[key] = this.multiData[key].map((data: IDataPair) => {
            data.selected = false;
            return data;
          });
        });
      }
    } else {
      if (this.allSelected) {
        this.formControl.patchValue([...this.data]);
      } else {
        this.formControl.setValue([]);
      }
    }
    this.formControl.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public multiToggleSelection(key: string): void {
    const index = this.multiSelected.indexOf(key);
    // Find if key is found in multiSelected to see if it is already selected or not
    if (index > -1) {
      // If already selected, we want to remove all values in formControl that has keys matching with list of keys in multiData
      this.multiSelected.splice(index, 1);
      this.multiData[key] = this.multiData[key].map((multi: IDataPair) => {
        multi.selected = false;
        return multi;
      });
      const result = this.formControl.value.filter((dataPair: IDataPair) => {
        return !this.multiData[key].some(
          (val: IDataPair) => val.key === dataPair.key
        );
      });
      this.formControl.setValue(result);
      this.allSelected = false;
    } else {
      // If not selected, we want to add key to multiSelected to record it being selected
      this.multiSelected.push(key);
      this.multiData[key] = this.multiData[key].map((multi: IDataPair) => {
        multi.selected = true;
        return multi;
      });
      this.formControl.patchValue([
        ...this.formControl.value,
        ...this.multiData[key],
      ]);
    }
    if (this.multiKeys.length === this.multiSelected.length) {
      this.allSelected = true;
    }
    this.formControl.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public compareFn(x?: IDataPair, y?: IDataPair): boolean {
    if (x && y) return x.key === y.key;
    return false;
  }

  public get multiKeys(): string[] {
    return Object.keys(this.multiData);
  }

  public multiSelectedCheck(key: string): boolean {
    return this.multiSelected.some((val: string) => val === key);
  }

  public filterToggleSelection(val: IDataPair): void {
    val.selected = !val.selected;
    let valuesSelected = this.formControl.value;
    const valSelected = valuesSelected.findIndex(
      (v: IDataPair) => v.key === val.key
    );
    if (valSelected < 0) {
      valuesSelected.push(val);
    } else {
      valuesSelected.splice(valSelected, 1);
    }
    this.formControl.patchValue(valuesSelected);
    this.formControl.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
    this.toggleSelection(val.key);
  }

  public filterSelectionCheck(key: string): boolean {
    return this.formControl.value.some((val: IDataPair) => val.key === key);
  }

  public filterSelection(): void {
    let keys = Object.keys(this.multiData);
    this.filteredData = [];
    if (this.data.length > 0) {
      this.filteredData = this.data.filter(
        (val) =>
          val.value
            .toLowerCase()
            .indexOf(this.filterInput.value.toLowerCase()) >= 0
      );
    } else if (keys.length > 0) {
      keys.forEach((key) => {
        this.filteredData = this.filteredData.concat(
          this.multiData[key].filter(
            (val: IDataPair) =>
              val.value
                .toLowerCase()
                .indexOf(this.filterInput.value.toLowerCase()) >= 0
          )
        );
      });
    }
  }

  public clearFilter(event: boolean): void {
    if(!event) {
      this.filterInput.setValue('');
    }
  }
}
