import { Component, Input } from '@angular/core';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent {
  @Input() message = '';
  constructor(private _errorService: ErrorService) {}

  public closeGeneralError(): void {
    this._errorService.showGeneralError(false);
  }
}
