import { IStore, IStoreAttributes } from '../services/location.service';

export class Store implements IStore {
  private _id: string;
  private _attributes: IStoreAttributes;

  constructor(storeResponse: IStore) {
    this._id = storeResponse.id;
    this._attributes = JSON.parse(JSON.stringify(storeResponse.attributes));
  }

  public get id(): string {
    return this._id;
  }

  public get attributes(): IStoreAttributes {
    return this._attributes;
  }

  public get category(): string {
    return this.attributes.category;
  }

  public get name(): string {
    return this.attributes.name;
  }

  public get banner(): string {
    return this.attributes.banner;
  }

  public get regionName(): string | undefined {
    return this.attributes.regionName;
  }

  public get longitude(): number {
    return this.attributes.geolocation.longitude;
  }

  public get latitude(): number {
    return this.attributes.geolocation.latitude;
  }
}
