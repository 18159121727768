import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public loading$: Observable<boolean> = this._loading.asObservable();
  private _state = false;

  public toggleLoading(): void {
    this._state = !this._state;
    this._loading.next(this._state);
  }

  public startLoading(): void {
    this._state = true;
    this._loading.next(this._state);
  }

  public stopLoading(): void {
    this._state = false;
    this._loading.next(this._state);
  }

  public get state(): boolean {
    return this._state;
  }
}
