import { ICDCData, ICDCProfile } from '../services/cdc.service';

export class Client {
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _phone: string;
  private _ids: string[]; //all ids
  private _ceIds: string[];
  private _sfccIds: string[];
  private _xstoreIds: string[];
  constructor(data: ICDCData, profile?: ICDCProfile) {
    this._firstName = profile?.firstName ? profile?.firstName : '';
    this._lastName = profile?.lastName ? profile?.lastName : '';
    this._email = profile?.email ? profile?.email : '';

    if(data.phones) {
      if (data.phones.length === 1) {
        this._phone = data.phones[0].number ? data.phones[0].number : '';
      } else if (data.phones.filter((phone) => phone.preferred).length > 0) {
        this._phone = data.phones.filter((phone) => phone.preferred)[0].number;
      } else if (data.phones.length > 0) {
        this._phone = data.phones[0].number ? data.phones[0].number : '';
      } else {
        this._phone = '';
      }
    } else {
      this._phone = '';
    }

    this._sfccIds = data.mappedIDs?.sfcc || [];
    this._ceIds = data.mappedIDs?.ce || [];
    this._xstoreIds = data.mappedIDs?.xstore || [];
    this._ids = [...this._xstoreIds, ...this._ceIds, ...this._sfccIds];
  }

  public get firstName() {
    return this._firstName;
  }

  public get lastName() {
    return this._lastName;
  }

  public get email() {
    return this._email;
  }

  public get phone() {
    return this._phone;
  }

  public get ids() {
    return this._ids;
  }

  public get retailIds() {
    return this._ceIds.concat(this._xstoreIds);
  }

  public get ceIds() {
    return this._ceIds;
  }

  public get sfccIds() {
    return this._sfccIds;
  }

  public get xstoreIds() {
    return this._xstoreIds;
  }
}
