import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

class DateRangeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(form && form.invalid);
  }
}

export function DateRangeValidator(): ValidatorFn | null {
  return (control: AbstractControl): ValidationErrors | null => {
    if(!control.value.start && !control.value.end){
      return {bothDateError: "Invalid or missing date"} as ValidationErrors;
    }else if(control.value.start === null){
      return {startDateError: "Start Date invalid or missing"} as ValidationErrors;
    }else if(control.value.end === null){
      return {endDateError: "End Date invalid or missing"} as ValidationErrors;
    }else{
      return null;
    }
  };
}

export interface IDateRange{
  start: Date,
  end: Date,
}

@Component({
  selector: 'date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @Input() public label: string = this._translateService.instant('shared.dateRangePicker.enterDateRange');
  @Input() public defaultStart: Date;
  @Input() public defaultEnd: Date;
  @Input() public formGroup: FormGroup<{start: FormControl<Date>; end: FormControl<Date>}> = new FormGroup({
    start: new FormControl<Date>(null),
    end: new FormControl<Date>(null),
  },[DateRangeValidator()]);

  matcher = new DateRangeErrorStateMatcher();
  constructor(
    private _translateService: TranslateService
  ) {

  }

  ngOnInit(): void {   
  }
}

