import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Transaction } from '../models/transaction.model';
import { ArticleService } from '../services/article.service';
import { ErrorService } from '../services/error.service';
import { LoadingService } from '../services/loading.service';

@Injectable({
  providedIn: 'root',
})
export class ArticleResolver implements Resolve<Transaction[]> {
  // skipcq
  constructor(private _errorService: ErrorService, private _articleService: ArticleService, private _loadingService: LoadingService) {}
  resolve(route: ActivatedRouteSnapshot): Observable<Transaction[]> {
    if(this._articleService.articleTransactions.length > 0) {
      return of(this._articleService.articleTransactions);
    }
    const articleId = route.paramMap.get('articleId');
    const storeId = route.paramMap.get('storeId');
    const articleDate = route.paramMap.get('articleDate');
    this._loadingService.startLoading();
    return this._articleService.initTransactionsByArticle(articleId, storeId, articleDate).pipe(
      map((val) => {
        this._loadingService.stopLoading();
        return val;
      }),
      catchError((e) => {
        this._loadingService.stopLoading();
        console.log(e);
        return of(null);
      })
    );
  }
}
