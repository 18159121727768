import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ErrorService } from '../../services/error.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { OrderService } from '../../services/order.service';

@Component({
  selector: 'order-search-page',
  templateUrl: './order-search-page.component.html',
  styleUrls: ['./order-search-page.component.scss'],
})
export class OrderSearchPageComponent implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();

  constructor(
      public orderService: OrderService,
      private _router: Router,
      private _msAuthService: MsAuthService,
      private _errorService: ErrorService,
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
        this._msAuthService.loggedIn$.subscribe((loggedIn: boolean) => {
          if (!loggedIn) {
            this._errorService.showError('errors.notLoggedIn');
            this._router.navigateByUrl(`/client-search`);
          }
        })
    );

    if (this.orderService.orders && this.orderService.orders.length === 1) {
      this._router.navigateByUrl(
        `/order/${this.orderService.orders[0].orderId}`
      );
    }
    if (!this.orderService.orders || this.orderService.orders.length === 0) {
      this._router.navigateByUrl(`/client-search`);
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public get totalOrders() {
    const orders = this.orderService.orders;
    if (orders && orders.length > 0) {
      return orders[0].totalCount === 0 ? orders.length : orders[0].totalCount;
    }
    return 0;
  }
}
