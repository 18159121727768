import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IDictionary } from '../shared/models/dictionary.model';
import { ErrorService } from './error.service';
import { MsAuthService } from './ms-auth.service';

export interface IToken {
  expiration: number,
  expires_in: string,
  scopes: string[],
  gcpScopeList: IDictionary,
  name: string,
  status: string,
  email: string,
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _host = '';
  private _token: IToken | undefined;
  private _tokenTimer: any; // skipcq
  private _msAuthToken: string|null  = null;

  constructor(
    private _msAuthService: MsAuthService,
    private httpClient: HttpClient, // skipcq
    private _errorService: ErrorService // skipcq
  ) {
    this._msAuthService.silentToken$.subscribe((msToken) => {
      this._msAuthToken = msToken;
    })
  }

  public initialize(): Observable<IToken> {
    if(this._msAuthService.hasToken()) {
      this._host = environment.apigee.host + environment.apigee.tokenPath;
      return this.generateToken();
    } else {
      this._errorService.showError('errors.notLoggedIn');
      throw new Error('Not Logged In');
    }
  }

  private generateToken(): Observable<IToken> {
    const options = {
      headers: new HttpHeaders({
        Skip: 'true',
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${this._msAuthToken}`,
        'X-API-Key': process.env.NG_APP_CLIENT_ID
      }),
      withCredentials: true
    };

    return this.httpClient.post<IToken>(this._host, '', options).pipe(
      map((data) => {
        this._token = data;
        this._token.expiration = Date.now() / 1000 + Number.parseFloat(this._token.expires_in);
        this.tokenUpdater();
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        this._errorService.showGeneralError(true);
        throw error;
      })
    )
  }

  private tokenUpdater(): void {
    if (this._tokenTimer) {
      return;
    } else {
      this._tokenTimer = setTimeout(() => {
        this._tokenTimer = undefined;
        if (this._token && Date.now() / 1000 > this._token.expiration) {
          this.generateToken();
          this._token = undefined;
        } else {
          this.tokenUpdater();
        }
      }, 30000);
    }
  }

  public get token(): IToken | undefined {
    return this._token;
  }

  public hasProjectScope(project:string){
    return !!this.token.gcpScopeList[project]
  }

  public hasProjectTopicScope(project: string, topic: string){
    return this.token.gcpScopeList[project]?.topics?.includes(topic);
  }
}
