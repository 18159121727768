import { Component, Input, OnInit } from '@angular/core';
import { Transaction } from '../../models/transaction.model';

@Component({
  selector: 'transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class TransactionDetailComponent implements OnInit {
  @Input() transaction: Transaction | undefined
  constructor() { }

  ngOnInit(): void {
  }

}
