import { Component, Input } from '@angular/core';
import { Order } from '../../models/order.model';

@Component({
  selector: 'order-number-header',
  templateUrl: './order-number-header.component.html',
  styleUrls: ['./order-number-header.component.scss'],
})
export class OrderNumberHeaderComponent {
  @Input() order: Order;

  public get orderId() {
    if (this.order) {
      return this.order.isRetail ? this.order.saleChId : this.order.orderId;
    } else {
      return '';
    }
  }
}
