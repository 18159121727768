<div class="container">
  <div class="title-wrapper" *ngIf="!showSearchButton">
    <div class="top-title-container">
      <img *ngIf="!isIframe()" src="../../../assets/images/aritzia_logo.svg" alt="Aritzia" />
      <div class="right-title-container">
        <div *ngIf="router.url.split('/')[1] === 'client-search'" class="toggle">
          <mat-label class="toggle-label">{{ 'banner.english' | translate }}</mat-label>
          <mat-slide-toggle
            [checked]="isSlideChecked()"
            (change)="toggleLanguage($event.checked)"
            >{{ 'banner.french' | translate }}</mat-slide-toggle
          >
        </div>
        <button
        class="login-button"
          mat-raised-button
          *ngIf="!isIframe()"
          (click)="loginButtonClicked()"
        >
          {{ loginButtonText }}
        </button>
      </div>

    </div>
    <h2 class="header-text">{{ 'banner.header' | translate }}</h2>
  </div>
  <button
    mat-icon-button
    class="button"
    *ngIf="showSearchButton"
    (click)="backPressed()">
    <mat-icon> arrow_back </mat-icon>
  </button>
  <button
    mat-icon-button
    class="button"
    *ngIf="showSearchButton"
    (click)="searchPressed()">
    <mat-icon> search </mat-icon>
  </button>
</div>
