import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MsAuthService } from '../../services/ms-auth.service';

@Component({
  selector: 'banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() showSearchButton = false;
  @Output() readonly searchButtonEmitter = new EventEmitter<boolean>();
  @Output() readonly backButtonEmitter = new EventEmitter<boolean>();
  public loginButtonText = this._translateService.instant('msal.login');
  constructor(
    public router: Router,
    public msAuthService: MsAuthService,
    private _translateService: TranslateService,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private _locale: string,
    ){
    msAuthService.loggedIn$.subscribe((val) => {
      if(val) {
        this.loginButtonText = this._translateService.instant('msal.logout');
      } else {
        this.loginButtonText = this._translateService.instant('msal.login');
      }
    })
  }
  
  public searchPressed(): void {
    this.searchButtonEmitter.emit(true);
  }

  public backPressed(): void {
    this.backButtonEmitter.emit(true);
  }

  public isIframe(): boolean {
    return window !== window.parent && !window.opener;
  }
  public loginButtonClicked() {
    if(this.msAuthService.isLoggedIn()) {
      this.msAuthService.logout();
    } else {
      this.msAuthService.login();
    }
  }

  public toggleLanguage(checked: boolean): void {
    if (checked) {
      localStorage.setItem('lang', 'fr');
      this._translateService.use('fr');
      this._locale = 'fr';
      this._adapter.setLocale(this._locale);
    } else {
      localStorage.setItem('lang', 'en');
      this._translateService.use('en');
      this._locale = 'en';
      this._adapter.setLocale(this._locale);
    }
  }
  
  public isSlideChecked(): boolean {
    return localStorage.getItem('lang') === 'fr';
  }
}
