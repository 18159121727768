import {
  IIncluded,
  IIncludeShipmentAttributes,
  IOrderAddress, IOrderData,
  IOrderProduct,
  IPayment
} from '../services/order.service';
import { Item } from './item.model';

export class Order {
  private _type: string;
  private _firstName: string;
  private _lastName: string;
  private _email: string;
  private _phone: string;
  private _status: string;
  private _orderType: string;
  private _orderId: string;
  private _saleChId: string;
  private _isRetail: boolean;
  private _orderDate: string;
  private _orderTimestamp: string;
  private _totalCount: number;
  private _clientId: string;
  private _initiatingStore: string;
  private _pickupStore: string;
  private _serviceLevel: string;
  private _billingAddress: IOrderAddress;
  private _shippingAddress: IOrderAddress;
  private _originalPrice: number;
  private _discount: number;
  private _subtotal: number;
  private _tax: number;
  private _shippingFee: number;
  private _total: number;
  private _currency: string;
  private _items: Item[];
  private _saleAssociateId?: string;
  private _cashierId?: string;
  private _returnedOrders: Order[] = [];
  private _hasReturns: boolean;
  private _transactionType: string;
  private _transactionRefId: string;
  private _payments: IPayment[] = [];
  private _registerId: string;
  private _transactionId: string;

  public constructor(
      order: IOrderData,
      totalOrders: number,
      included?: IIncluded[],
      hasReturns?: boolean
  ) {
    this._orderId = order.id;
    if (order.attributes.addresses) {
      this._firstName = order.attributes.addresses[0]?.firstName; //Replace with cdc
      this._lastName = order.attributes.addresses[0]?.lastName; //Replace with cdc
      this._email = order.attributes.addresses[0]?.email; //Replace with cdc
      this._phone = order.attributes.addresses[0]?.phoneNumber; //Replace with cdc      
      this._billingAddress = order.attributes.addresses?.find(
          (address) => address.addressTypeName === 'Billing'
      );
      this._shippingAddress = order.attributes.addresses?.find(
          (address) => address.addressTypeName === 'Shipping'
      );
    }

    this._items = order.attributes.orderProducts.filter((o) => !o.productName.includes('SHIPPING FEE'))
        .map(
            (orderProduct) =>
                new Item(orderProduct, this._getTrackingNumber(orderProduct, included))
        );

    this._calculateReturns(order, included);

    this._orderType = this._calculateOrderType(
        order.attributes.locationId,
        order.attributes.orderProducts[0],
        order.attributes.orderTypeCode,
        order.attributes.orderTypeName
    );

    this._status = this._calculateStatus();
    this._pickupStore = order.attributes.orderProducts[0].pickupLocationId;
    this._originalPrice = this._filterShippingCost(true, order.attributes.orderProducts, 'initialPrice');
    this._discount = this._filterShippingCost(true, order.attributes.orderProducts, 'discountTotal');
    this._shippingFee = this._filterShippingCost(false, order.attributes.orderProducts, 'total');
    this._saleChId = order.attributes.salesChannelOrderId;
    this._isRetail = order.attributes.salesChannelId === 'RETAIL';
    this._type = this._calculateType(); // RETAILORDER ECOMMORDER RETAILTRANSACTION
    this._orderDate = order.attributes.orderDate;
    this._orderTimestamp = order.attributes.orderTimestamp;
    this._totalCount = totalOrders;
    this._clientId =
        order.attributes.customerReferenceId.length > 1
            ? order.attributes.customerReferenceId
            : order.attributes.customerId;
    this._initiatingStore = order.attributes.locationId;
    this._serviceLevel = this._calculateServiceLevel(
        order.attributes.shippingConditionCode,
        order.attributes.orderTypeCode
    );


    this._subtotal = order.attributes.subtotal;
    this._tax = order.attributes.tax;

    this._total = order.attributes.total;
    this._currency = order.attributes.currency;
    this._saleAssociateId = order.attributes.associateId;
    this._cashierId = order.attributes.cashierId;
    this._payments = order.attributes.payments;
    this._registerId = order.attributes.registerId;
    this._transactionId = this._type === "RETAILTRANSACTION" ? order.id : '';
    
    if(this._type === 'ECOMMORDER') {
      this._hasReturns = this._returnedOrders && this._returnedOrders.length > 0;
    } else {
      this._hasReturns = hasReturns ? hasReturns : false;
    }
    this._transactionRefId = order.transactionRefId;
    this._transactionType = order.transactionType;
  }

  public get type(): string {
    return this._type;
  }

  public get firstName(): string {
    return this._firstName ? this._firstName : '';
  }

  public get lastName(): string {
    return this._lastName ? this._lastName : '';
  }

  public get email(): string {
    return this._email ? this._email : '';
  }

  public get phone(): string {
    return this._phone ? this._phone : '';
  }

  public get status(): string {
    return this._status;
  }

  public get orderType(): string {
    return this._orderType;
  }

  public get orderId(): string {
    return this._orderId;
  }

  public get saleChId(): string {
    return this._saleChId;
  }

  public get isRetail(): boolean {
    return this._isRetail;
  }

  public get orderDate(): string {
    return this._orderDate;
  }

  public get orderTimestamp(): string {
    return this._orderTimestamp;
  }

  public get totalCount(): number {
    return this._totalCount;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get initiatingStore(): string {
    return this._initiatingStore;
  }

  public get pickupStore(): string {
    return this._pickupStore;
  }

  public get serviceLevel(): string {
    return this._serviceLevel;
  }

  public get billingAddress(): IOrderAddress {
    return this._billingAddress ? this._billingAddress : undefined;
  }

  public get shippingAddress(): IOrderAddress {
    return this._shippingAddress ? this._shippingAddress : undefined;
  }

  public get originalPrice(): number {
    return this._originalPrice;
  }

  public get discount(): number {
    return this._discount;
  }

  public get subtotal(): number {
    return this._subtotal;
  }

  public get tax(): number {
    return this._tax;
  }

  public get shippingFee(): number {
    return this._shippingFee;
  }

  public get total(): number {
    return this._total;
  }

  public get currency(): string {
    return this._currency;
  }

  public get items(): Item[] {
    return this._items;
  }

  public get saleAssociateId(): string {
    return this._saleAssociateId ? this._saleAssociateId : '';
  }

  public get cashierId(): string {
    return this._cashierId ? this._cashierId : '';
  }

  public get returnItems(): Item[] {
    let items: Item[] = [];
    if (this._returnedOrders) {
      this._returnedOrders.forEach((val) => {
        items.push(...val.items);
      });
    }
    return items;
  };

  public get returnOrders(): Order[] {
    return this._returnedOrders;
  }

  public get returnCount(): number {
    return this.returnItems.length;
  }
  public get hasReturns(): boolean {
    return this._hasReturns;
  }
  public get payments(): IPayment[] {
    return this._payments;
  }

  public get registerId(): string {
    return this._registerId;
  }

  public get transactionId(): string {
    return this._transactionId;
  }
  public get transactionType(): string {
    return this._transactionType ? this._transactionType : '';
  }
  public get transactionRefId(): string {
    return this._transactionRefId ? this._transactionRefId : '';
  }

  public get isDeliveryOrder(): boolean{
    return this._orderType === 'order.retailDelivery' || this._orderType === 'order.ecomSpecialDelivery' || this._orderType === 'order.ecomDelivery';
  }

  public get isPickupOrder(): boolean{
    return this._orderType === 'order.ecomPickup' || this._orderType === 'order.retailPickup';
  }

  public get isTransferOrder(): boolean{
    return this._orderType === 'order.retailTransfer';
  }

  private _filterShippingCost(filterOut: boolean, products: IOrderProduct[], key: string): number {
    return filterOut ?
        products
            .filter((o) => !o.productName.includes('SHIPPING FEE'))
            .reduce((prev, curr) => {
              return prev + curr[key];
            }, 0) :
        products
            .filter((o) => o.productName.includes('SHIPPING FEE'))
            .reduce((prev, curr) => {
              return prev + curr[key];
            }, 0);
  }

  private _calculateStatus(): string {
    const itemStatuses = this._items.map((item) => {
      switch (item.status) {
        case 'item.unprocessed':
        case 'item.newOrder':
        case 'item.polled':
        case 'item.accepted':
        case 'item.intransit':
        case 'item.intransitPolled':
          return 'order.inProgress';
        case 'item.received':
          return 'order.readyForPickup';
        case 'item.fulfilled':
          return 'order.complete';
        case 'item.rejected':
        case 'item.unfulfilled':
          return 'order.unfulfillable';
        case 'item.cancelled':
          return 'order.cancelled';
        case 'item.picked':
          if (this.isDeliveryOrder) {
            return 'order.readyToShip';
          } else if (this.isTransferOrder) {
            return 'order.inProgress';
          } else if (this.isPickupOrder) {
            return 'order.readyForPickup';
          } else {
            return 'order.unknown';
          }
        default:
          return 'order.unknown';
      }
    });

    //If only one line item
    if (itemStatuses.length === 1) {
      return itemStatuses[0];
    }

    //If multi line items
    if (this.isDeliveryOrder) {
      if (itemStatuses.some((i) => i === 'order.inProgress')) {
        if (itemStatuses.some((i) => i === 'order.complete')) {
          return 'order.partiallyShipped';
        } else {
          return 'order.inProgress';
        }
      } else {
        if (itemStatuses.some((i) => i === 'order.complete')) {
          return 'order.complete';
        } else {
          if (itemStatuses.some((i) => i === 'order.cancelled')) {
            return 'order.cancelled';
          } else {
            //This should never be reached according to the chart given
            return 'order.unknown';
          }
        }
      }
    } else {
      if (itemStatuses.some((i) => i === 'order.inProgress')) {
        return 'order.inProgress';
      } else if (itemStatuses.some((i) => i === 'order.readyForPickup')){
        return 'order.readyForPickup';
      } else if(itemStatuses.some((i) => i === 'order.complete')) {
        return 'order.complete';
      } else if (itemStatuses.some((i) => i === 'order.cancelled')) {
        return 'order.cancelled';
      } else {
        //This should never be reached according to the chart given
        return 'order.unknown';
      }
    }
  }

  private _calculateOrderType(
      location: string,
      product: IOrderProduct,
      type: string,
      name: string
  ): string {
    switch (name.trim()) {
      case 'eCom Omni Return': // orderTypeName: eCom Omni Return
        return 'order.storeReturn';
      case 'eComm Return Order': // orderTypeName: eComm Return Order
        return 'order.dcReturn';
    }
    switch (type) {
      case 'ZODE': // Exclusively for POS Order Deliveries
        return 'order.retailDelivery';
      case 'ZEBK': // eCom Bulk Order
      case 'ZECM': // eCom Standard Order
      case 'ZEOB': // eCom Order Billing
      case 'ZEXC': // eCom Exchange Order
        if(this._items.length > 0 &&
          this._items.find(item=> item.backOrderFlag && item.merchandiseCategoryCode != 'AWT03')
          ){
          return 'order.ecomSpecialDelivery'
        }
        return 'order.ecomDelivery';
      case 'ZEPU': // eCom Pickup
        return 'order.ecomPickup';
      case 'ZOPU': // Retail pickup order
        return location === product.pickupLocationId
            ? 'order.retailTransfer' // Retail transfer
            : 'order.retailPickup'; // Retail pickup
      default:
        return name;
    }
  }

  private _calculateServiceLevel(id: string, type: string): string {
    if (type === 'ZEPU' || type === 'ZOPU') return '';

    switch (id) {
      case 'Z1':
        return 'order.priority';
      case 'Z2':
        return 'order.express';
      case 'Z3':
        return 'order.standard';
      case 'Z4':
        return 'order.standard';
      case 'Z5':
        return 'order.standard';
      case 'Z6':
        return 'order.express';
      case 'I1':
        return 'order.intlStdPacket';
      case 'I2':
        return 'order.intlStdPriority';
      case 'I3':
        return 'order.intlStdDirect';
      case 'D1':
        return 'order.priority';
      case 'D2':
        return 'order.express';
      case 'D3':
        return 'order.standard';
      default:
        return 'order.unknown';
    }
  }

  private _getTrackingNumber(
      orderProduct: IOrderProduct,
      included?: IIncluded[]
  ): string[] {
    const trackingNumbers = <string[]>[];
    const shipmentAttr = included
        ?.filter((i) => i.type === 'shipments')
        ?.map((shipment) => shipment.attributes as IIncludeShipmentAttributes);
    if (shipmentAttr && shipmentAttr.length > 0) {
      const relatedShipment = shipmentAttr.filter((attr) => attr.shipmentOrderId === this.orderId);
      if(relatedShipment.length > 0) {
        relatedShipment.forEach((shipment) => {
          shipment.shipmentProducts.forEach((product) => {
            if (product.orderLineItem === orderProduct.lineItem) {
              product.trackingNumbers.forEach((t) => {
                if (t.trackingItemTypeCode === 'Z001') {
                  trackingNumbers.push(t.trackingNumber);
                }
             })
            }
          })
        })
      }
    }

    return trackingNumbers;
  }

  private _calculateType(): string {
    if (this._isRetail && this._saleChId) {
      return "RETAILORDER";
    } else if (this._isRetail) {
      return "RETAILTRANSACTION";
    } else {
      return "ECOMMORDER";
    }
  }

  private _calculateReturns(order: IOrderData, included: IIncluded[]): void {
    //Figures out the return orders and images for each item
    const returnOrdersIds = order.relationships?.returns?.data.map((val) => val.id);
    if (returnOrdersIds && returnOrdersIds.length > 0) {
      this._returnedOrders = included?.filter((val) => returnOrdersIds.some((r) => r === val.id))
          .map((val) => {
            return new Order(val as IOrderData, 1)
          });
    }
  }
}


