<div class="container">
  <table mat-table [dataSource]="dataSource" class="table">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef class="header-value">
        {{ 'orderItemSummary.date' | translate }}/<br />{{
          'orderItemSummary.time' | translate
        }}
      </th>
      <td mat-cell *matCellDef="let element" class="data-value">
        {{ getDateTime(element.timestamp) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef class="header-value">
        {{ 'orderItemSummary.location' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="data-value">
        {{ element.fulfillmentLocationId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef class="header-value">
        {{ 'orderItemSummary.user' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="data-value">
        {{ getUserId(element.userId) }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="header-value">
        {{ 'orderItemSummary.status' | translate }}
      </th>
      <td mat-cell *matCellDef="let element; let i = index" class="data-value">
        <!--
        The functions and ngclass needs to be moved back to the td above ^ 
        (mousedown)="mouseDownRejected(element.statusName, element.rejectionReasonCode, i)"
        (touchstart)="mouseDownRejected(element.statusName, element.rejectionReasonCode, i)"
        (mouseup)="mouseUpRejected()"
        (touchend)="mouseUpRejected()"
        [ngClass]="{ 'rejected-status': element.statusName === 'REJECTED' }"
        <mat-card *ngIf="displayToolTip === i" class="tooltip" #tooltip>
          <p>Reason for Rejection:</p>
          <p>{{ reasonForRejection }}</p>
        </mat-card> -->
        {{ element.statusName | translate }}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
  </table>
</div>
