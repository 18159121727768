<mat-form-field class="container" appearance="outline">
  <mat-select
    color="'primary'"
    [formControl]="formControl"
    [multiple]="multiple"
    [compareWith]="compareFn"
    [placeholder]="label"
    (openedChange)="clearFilter($event)"
    #selector
  >
    <mat-form-field *ngIf="enableFilter" class="filter-field" appearance="fill">
      <input
        [formControl]="filterInput"
        matInput
        type="text"
        [placeholder]="filterText"
        (keyup)="filterSelection()"
      />
      <button
        *ngIf="filterInput"
        matSuffix
        mat-icon-button
        aria-label="Clear"
        (click)="filterInput.setValue('')"
      >
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <div [hidden]="filterInput.value">
      <div class="all-option" *ngIf="multiple">
        <mat-checkbox
          [color]="'primary'"
          [(ngModel)]="allSelected"
          [ngModelOptions]="{ standalone: true }"
          (change)="toggleAllSelection()"
          data-cy="all-option"
          >{{ 'shared.dropDown.all' | translate }}</mat-checkbox
        >
      </div>

      <div class="multi-padding" *ngIf="multiKeys.length > 0">
        <div *ngFor="let key of multiKeys">
          <mat-checkbox
            class="checkbox"
            [color]="'primary'"
            [checked]="multiSelectedCheck(key)"
            (change)="multiToggleSelection(key)"
          >
            {{ key }}
          </mat-checkbox>
          <mat-option
            *ngFor="let value of multiData[key]"
            [value]="value"
            (click)="toggleSelection(value.key)"
          >
            {{ value.value }}
          </mat-option>
        </div>
      </div>
      <mat-option
        *ngFor="let value of data"
        [value]="value"
        (click)="toggleSelection(value.key)"
      >
        {{ value.value }}
      </mat-option>
    </div>

    <div class="checkbox-filter-container" *ngIf="filterInput.value && multiple">
      <mat-checkbox
        class="checkbox-filter"
        [color]="'primary'"
        *ngFor="let value of filteredData"
        [checked]="filterSelectionCheck(value.key)"
        (change)="filterToggleSelection(value)"
      >
        {{ value.value }}
      </mat-checkbox>
    </div>
    <div *ngIf="filterInput.value && !multiple">
      <mat-option
        *ngFor="let value of filteredData"
        [value]="value"
        (click)="toggleSelection(value.key)"
      >
        {{ value.value }}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
