import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService
} from '@azure/msal-angular';
import {
  AuthenticationResult, RedirectRequest,
  SilentRequest
} from '@azure/msal-browser';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { TimeoutPopupComponent } from '../shared/timeout-popup/timeout-popup.component';
import { ErrorService } from './error.service';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class MsAuthService {
  private _silentToken: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  public silentToken$: Observable<string | null> =
    this._silentToken.asObservable();

  private _resolveToken: Subject<string | null> = new Subject<
    string | null
  >();
  public resolveToken$: Observable<string | null> =
    this._resolveToken.asObservable();


  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    this.isLoggedIn()
  );
  public loggedIn$: Observable<boolean> = this._loggedIn.asObservable();

  private _timeoutPrompted: boolean = false;
  private _tokenTimer: any; // skipcq
  private _accessToken: AuthenticationResult | undefined;
  private _groups: string[] = [];
  private _activeTimeoutCounter: any; // skipcq

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private _msalAuthService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private _idle: Idle,
    private _dialog: MatDialog,
    private _error: ErrorService,
    private _httpClient: HttpClient
  ) {
    _idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    _idle.onIdleStart.subscribe(() => {
      if (!this._timeoutPrompted) this._promptTimeoutPopup();
    });

    // Handles when token expires and it fails to aquire it. Force user to relogin.
    this._msalBroadcastService.msalSubject$.subscribe({
      next: (msalSubject) => {
        if (msalSubject.eventType === 'msal:acquireTokenFailure') {
          this.login();
        }
      },
    });

    this._msalAuthService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        this._loggedIn.next(this.isLoggedIn());
        if (this.isLoggedIn()) {
          this.fetchAccessToken();
          this.setIdle();
        } else {
          this._silentToken.next(null);
          this._idle.stop();
        }
      },
      error: (err) => {
        // TODO: Handle errors
        console.error(err);
        if (this.isLoggedIn()) {
          this._error.showError('errors.logout');
        } else {
          this._error.showError('errors.login');
        }
      }
    });
  }

  private setIdle() {

    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    
    //Check if we are in production and updates env to PRD to be used for groups
    let env = 'QA';
    if(environment.production) {
      env = 'PRD';
    }

    //Check if user is in retail group
    const isRetail = this.isRetail();
    //Check if user is using an iframe
    const isIframe = window !== window.parent && !window.opener;
    //Check if user is using a zebra device
    const isZebra = window.navigator.userAgent.includes(' EC50 ');

    let inactiveTimeout = 0;
    let activeTimeout = 0;
    // Time is calculated in seconds. 0 means infinite
    if(isRetail) {
      //On POS or Zebra
      if(isZebra) {
        // On EC50
        inactiveTimeout = environment.idle.zebraInactive;
        activeTimeout = environment.idle.zebraActive;
      } else {
        // Not EC50
        inactiveTimeout = environment.idle.posInactive;
        activeTimeout = environment.idle.posActive;
      }
    } else {
      //Everyone else
      if(isIframe) {
        //In twilio
        inactiveTimeout = environment.idle.twilioInactive;
        activeTimeout = environment.idle.twilioActive;
      } else {
        inactiveTimeout = environment.idle.inactive;
        activeTimeout = environment.idle.active;
      }
    }

    //Setting time outs
    if(inactiveTimeout > 0) {
      this._idle.setIdle(inactiveTimeout); // how long can they be inactive before considered idle, in seconds
      this._idle.watch();
    }
    if(activeTimeout > 0) {
      this._activeTimeoutCounter = setInterval(() => {this.logout()}, activeTimeout*1000);// how long can they be active before getting logged out, in milliseconds
    }
  }

  private _promptTimeoutPopup() {
    this._timeoutPrompted = true;
    this._dialog
      .open(TimeoutPopupComponent, {
        hasBackdrop: false,
      })
      .afterClosed()
      .subscribe((reset?: boolean) => {
        this._timeoutPrompted = false;
        if (reset) {
          this.setIdle();
        } else {
          this.logout();
        }
      });
  }

  // MS login
  login() {
    this._msalAuthService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest).subscribe({
      next: () => { },
      error: (error) => {
        console.log(error);
        this._error.showError('errors.login')
      }
    });
  }

  // MS logout
  logout() {
    if (this.isLoggedIn()) {
      this._msalAuthService
        .logoutRedirect({
          account: this._msalAuthService.instance.getAllAccounts()[0]
        })
        .subscribe({
          next: () => { },
          error: (error) => {
            console.log(error);
            this._error.showError('errors.logout')
          }
        });
    } else {
      this._loggedIn.next(this.isLoggedIn());
      this._error.showError('errors.logout')
    }
  }

  // Check if is logged in
  isLoggedIn(): boolean {
    return (
      !!this._msalAuthService.instance &&
      this._msalAuthService.instance.getAllAccounts().length > 0
    );
  }

  getAccountEmail(): string {
    return (
      this._msalAuthService.instance &&
      this._msalAuthService.instance.getAllAccounts().length > 0
    ) ? this._msalAuthService.instance.getAllAccounts()[0].username : "";
  }

  // Fetch access token
  async fetchAccessToken(refrsh?: boolean) {
    if (this.isLoggedIn()) {
      this._msalAuthService
        .acquireTokenSilent({
          ...this.msalGuardConfig.authRequest,
          account: this._msalAuthService.instance.getAllAccounts()[0],
          forceRefresh: refrsh
        } as SilentRequest)
        .subscribe({
          next: (result: AuthenticationResult) => {
            this.setAuthToken(result);
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type':  'application/json'
              })};
            const url = `${environment.apigee.host}/external/microsoft/graph/v1/users/${result.uniqueId}/memberOf/microsoft.graph.group?$search="displayName:_Role_API"&$select=displayName,employeeId&ConsistencyLevel=eventual`;
            this._httpClient.get(url, httpOptions)
            .subscribe((groups: any) => {
              this._groups = groups.value.filter((val) => val.displayName.includes('_Role_API')).map((val) => val.displayName);
            });
      
            const searchMe = `${environment.apigee.host}/external/microsoft/graph/v1/me?$select=employeeId`;
            this._httpClient.get(searchMe, httpOptions)
            .subscribe((result:any) => {
              datadogRum.setUser({
                id: result.employeeId,
              })
            });
          },
        });
    }
  }

  private setAuthToken(result: AuthenticationResult) {
    if (result && result.accessToken) {
      this._accessToken = result;
      this._silentToken.next(result.accessToken);
      this._resolveToken.next(result.accessToken);
      this.tokenUpdater();
    }
  }

  private tokenUpdater(): void {
    if (this._tokenTimer) {
      return;
    } else {
      this._tokenTimer = setTimeout(() => {
        this._tokenTimer = undefined;
        if (this._accessToken && new Date() > this._accessToken.expiresOn!) {
          this._accessToken = undefined;
          this.fetchAccessToken();
        } else {
          this.tokenUpdater();
        }
      }, 30000);
    }
  }

  public get token(): AuthenticationResult | undefined {
    return this._accessToken;
  }

  public hasToken(): boolean {
    return this.isLoggedIn() && this._accessToken != undefined;
  }
  
  public getName(): string {
    if (this.isLoggedIn()) {
      const name = this._msalAuthService.instance.getAllAccounts()[0].name;
      return name ? name : "";
    } else {
      return "";
    }
  }

  public isManager(): boolean {
    //Check if we are in production and updates env to PRD to be used for groups
    const env = environment.production ? 'PRD' : 'QA'
    if (this.isLoggedIn()) {
      return this._groups.includes("_Role_API_Concierge_Manager_"+env) || this._groups.includes("_Role_API_Production_Support_"+env) || this._groups.includes("_Role_API_Omni_BSA_"+env);
    } else {
      return false;
    }
  }

  public isRetail(): boolean {
    //Check if we are in production and updates env to PRD to be used for groups
    const env = environment.production ? 'PRD' : 'QA'
    //Check if user is in retail group
    return this._groups.includes("_Role_API_Retail_Associate_"+env) || this._groups.includes("_Role_API_Retail_Manager_"+env);
  }

  // Clear timeout and expiration timestamps from localstorage
  // clearMsStorage() {
  //   localStorage.removeItem(this._expirationKey);
  // }

  // Check if localstorage has token expiration timestamp
  // hasTokenExpiration(): boolean {
  //   return localStorage.getItem(this._expirationKey) != null;
  // }
}

