import { ITransactionData, ITransactionPayment } from "../services/transaction.service";
import { TransactionItem } from "./transaction-item.model";

export class Transaction {
  private _id: string;
  private _locationId: string;
  private _locationName: string;
  private _registerId: string;
  private _cashierId: string;
  private _associateId: string;
  private _currency: string;
  private _name: string;
  private _email: string;
  private _phone: string;
  private _items: TransactionItem[];
  private _payments: ITransactionPayment[];
  private _discount: number;
  private _originalPrice: number;
  private _subtotal: number;
  private _tax: number;
  private _shippingFee: number;
  private _total: number;
  private _transactionDate: string;
  private _transactionTimestamp: string;
  private _clientId: string;

  constructor(data: ITransactionData) {
    this._id = data.id;
    this._items = data.attributes.salesItems.filter((val) => !val.productName.includes('SHIPPING FEE')).map((item) => new TransactionItem(item));
    this._locationId = data.attributes.locationId;
    this._locationName = data.attributes.locationName;
    this._registerId = data.attributes.registerId;
    this._cashierId = data.attributes.cashierId;
    this._associateId = data.attributes.salesItems.filter((val) => !val.productName.includes('SHIPPING FEE'))[0]?.commissions[0]?.associateId;
    this._currency = data.attributes.currency;
    this._name = data.attributes.customer.find((val) => val.key === "NAME")?.value;
    this._email = data.attributes.customer.find((val) => val.key === "EMAIL")?.value;
    this._phone = data.attributes.customer.find((val) => val.key === "TELEPHONE")?.value;
    this._payments = data.attributes.payments;
    this._discount = data.attributes.salesItems.reduce((curr, next) => curr + next.discounts.reduce((dCurr, dNext) => dCurr + dNext.amount, 0), 0);
    this._originalPrice = data.attributes.salesItems.reduce((curr, next) => curr + next.initialPrice, 0);
    this._subtotal = data.attributes.salesItems.filter((val) => !val.productName.includes('SHIPPING FEE')).reduce((curr, next) => curr + next.salesPrice, 0);
    this._tax = data.attributes.salesItems.reduce((curr, next) => curr + next.taxes.reduce((dCurr, dNext) => dCurr + dNext.amount, 0), 0);
    this._shippingFee = data.attributes.salesItems.filter((val) => val.productName.includes('SHIPPING FEE')).reduce((curr, next) => curr + next.salesPrice, 0);
    this._total = this._subtotal + this._tax + this._shippingFee;
    this._transactionDate = data.attributes.postingDate;
    this._transactionTimestamp = data.attributes.startTimestamp;
    this._clientId = data.attributes.customerId;
  }

  public get id(): string {
    return this._id;
  }
  public get locationId(): string {
    return this._locationId;
  }
  public get locationName(): string {
    return this._locationName;
  }
  public get registerId(): string {
    return this._registerId;
  }
  public get cashierId(): string {
    return this._cashierId;
  }
  public get associateId(): string {
    return this._associateId ? this._associateId : '';
  }
  public get currency(): string {
    return this._currency;
  }
  public get name(): string {
    return this._name? this._name: '';
  }
  public get email(): string {
    return this._email? this._email: '';
  }
  public get phone(): string {
    return this._phone? this._phone: '';
  }
  public get items(): TransactionItem[] {
    return this._items;
  }
  public get payments(): ITransactionPayment[] {
    return this._payments;
  }
  public get discount(): number {
    return this._discount;
  }
  public get originalPrice(): number {
    return this._originalPrice;
  }
  public get subtotal(): number {
    return this._subtotal;
  }
  public get tax(): number {
    return this._tax;
  }
  public get shippingFee(): number {
    return this._shippingFee;
  }
  public get total(): number {
    return this._total;
  }
  public get transactionDate(): string {
    return this._transactionDate;
  }
  public get transactionTimestamp(): string {
    return this._transactionTimestamp;
  }
  public get clientId(): string {
    return this._clientId;
  }
}