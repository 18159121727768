import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Order } from '../../models/order.model';
import { LocationService } from '../../services/location.service';

@Component({
  selector: 'order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent {
  @Input() order: Order | undefined;
  @Input() original = true; 
  constructor(
    private _locationService: LocationService,
    private _translate: TranslateService
    ) {
  }

  public get initiatingStore(): string {
    if (this.order) {
      if (this.order.initiatingStore !== '') {
        return `${this.order.initiatingStore} ${
          this._locationService.stores.find(
            (store) => store.id === this.order.initiatingStore
          )?.name || ''
        }`;
      } else if (!this.order.isRetail) {
        return this._translate.instant('orderDetail.online');
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  public get pickupStore(): string {
    if (this.order && this.order.pickupStore !== '') {
      return `${this.order.pickupStore} ${
        this._locationService.stores.find(
          (store) => store.id == this.order.pickupStore
        )?.name || ''
      }`;
    } else {
      return '';
    }
  }

  public isRetailTransactionReturn(): boolean {
    return this.order.type == 'RETAILTRANSACTION' && !this.original;
  }
}
