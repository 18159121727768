import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent {
  @Input() label = '';
  @Input() autocomplete = false;
  @Input() type = '';
  @Input() placeholder = '';
  @Input() full = true;
  @Input() formControl = new FormControl();
  @Input() error: string;
  @Input() maxLength = '';
  @Output() readonly onFocus = new EventEmitter<any>();
  @Output() readonly onBlur = new EventEmitter<any>();

  @ViewChild('inputRef') inputRef;

  public get auto(): string {
    return this.autocomplete ? 'on' : 'off';
  }

  public blur() {
    this.inputRef.nativeElement.blur();
  }

  public handleFocus() {
    this.onFocus.emit();
  }

  public handleBlur() {
    this.onBlur.emit();
  }
}
