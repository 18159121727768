import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, fromEvent } from 'rxjs';
import { Transaction } from '../../models/transaction.model';
import { ArticleService } from '../../services/article.service';
import { ErrorService } from '../../services/error.service';
import { TransactionService } from '../../services/transaction.service';

@Component({
  selector: 'article-search-list',
  templateUrl: './article-search-list.component.html',
  styleUrls: ['./article-search-list.component.scss'],
})
export class ArticleSearchListComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport)
  viewport: CdkVirtualScrollViewport;

  public currentArray: Transaction[] = [];
  private _dataStream: BehaviorSubject<Transaction[]> = new BehaviorSubject<
    Transaction[]
  >([]);
  public dataStream$: Observable<Transaction[]> =
    this._dataStream.asObservable();
  public theEnd = false;
  public loading = false;
  private _firstLoad = true;
  private _subscriptions = new Subscription();

  constructor(
    private _articleService: ArticleService,
    private _transactionService: TransactionService,
    private _router: Router,
    private _errorService: ErrorService
  ) {}

  ngOnInit(): void {
    if (this._articleService.articleTransactions.length > 0) {
      this.currentArray = this._articleService.articleTransactions;
      this._dataStream.next(this.currentArray);
    } else {
      this._errorService.showError('errors.noTransaction');
      this._router.navigateByUrl(`/client-search`);
    }
  }

  ngAfterViewInit(): void {
    if(this.viewport) {
      this._subscriptions.add(fromEvent(this.viewport.elementRef.nativeElement,'scroll')
      .subscribe((e: Event) => 
        this._articleService.articleListYAxis = e.target['scrollTop']
      ));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  public nextBatch(): void {
    if (
      !this.loading &&
      !this.theEnd &&
      this.viewport &&
      this.viewport.getRenderedRange().end === this.viewport.getDataLength()
    )
      this.getBatch();
    if(this._firstLoad && this.viewport) {
      this._firstLoad = false;
      this.viewport.scrollTo({top: this._articleService.articleListYAxis});
    }
  }

  public transactionClicked(transaction: Transaction): void {
    this._transactionService.resetTransaction();
    const trnDate = transaction.transactionDate.replace(/-/g,"");
    this._router.navigateByUrl(
      `/transaction/${transaction.id}/${transaction.registerId}/${transaction.locationId}/${trnDate}`
    );
  }

  public getItemColour(transaction: Transaction): string[] {
    const matchedItems = transaction.items.filter((item) => item.genericId === this._articleService.articleId);
    return matchedItems.length > 3 ? ['Multiple'] : matchedItems.map((item) => item.colour);
  }

  public getItemSize(transaction: Transaction): string[] {
    const matchedItems = transaction.items.filter((item) => item.genericId === this._articleService.articleId);
    return matchedItems.length > 3 ? ['Multiple'] : matchedItems.map((item) => item.size);
  }

  private getBatch(): void {
    if (this.currentArray.length > 0) this.loading = true;
    if (this.currentArray.length >= this._articleService.articleTransactionTotal)
      this.theEnd = true;
    this._subscriptions.add(
      this._articleService.getNextArticleTransactions().subscribe(() => {
        this.currentArray = this._articleService.articleTransactions;
        this._dataStream.next(this.currentArray);
        this.loading = false;
      })
    );
  }
}
