import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';

export const scanRegex = /((^|, )(([A-Za-z]{1}\d{7})|(^[0-9]{6}$)|(^[0-9]{8}$)|(^ORD[0-9]+$)))+$/gm;


@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  private _scanStream = new Subject<string>();
  public scanDetected$ = this._scanStream.asObservable();

  private _ignoreInput = false;

  private _scanData: string[] = [];
  private _timeout: number;

  constructor(private _zone: NgZone) {}

  set ignoreInput(value: boolean) {
    this._ignoreInput = value;
  }

  public input(key: string) {
    if (this._ignoreInput) {
      return;
    }

    if (this._timeout) {
      this.resetTimeout();
    }

    this._scanData.push(key);
    this.createTimeout();
  }

  public onMessageScan(event: MessageEvent) {
    if (this._ignoreInput) {
      return;
    }

    this._zone.run(() => {
      this._scanStream.next(this.dataSanatizer(event.data));
    });
  }

  private dataSanatizer(data: string) {
    return data.match(scanRegex).toString();
  }

  private createTimeout() {
    this._timeout = window.setTimeout(() => {
      const scan = this._scanData.join('');
      this._scanData.length = 0;

      if (scan.length > 1) {
        this._scanStream.next(scan);
      }

      this.resetTimeout();
    }, 50);
  }

  private resetTimeout() {
    window.clearTimeout(this._timeout);
    this._timeout = null;
  }
}
