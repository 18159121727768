import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { share, tap } from 'rxjs/operators';

interface ITimeCache {
  expireTime: number;
  response: HttpResponse<any>;
}

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<string, ITimeCache> = new Map();

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes('inventory') ||
      request.url.includes('availability') ||
      request.url.includes('token') ||
      request.url.includes('orders')||
      request.url.includes('transactions') 
    ) {
      // we don't want to cache any of these requests
      return next.handle(request);
    }

    const cachedResponse = this.cache.get(request.urlWithParams);
    const now = Date.now();
    if (cachedResponse) {
      const { expireTime, response } = cachedResponse;
      if (now < expireTime) {
        return of(response.clone());
      }
      this.cache.delete(request.urlWithParams);
      return this.cacheRequest(request, next, now);
    }
    return this.cacheRequest(request, next, now);
  }

  clearCache() {
    this.cache.clear();
  }

  private cacheRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    timestamp: number
  ) {
    return next.handle(request).pipe(
      tap((stateEvent) => {
        const expireTime = timestamp + 1000 * 60 * 3;

        if (stateEvent instanceof HttpResponse) {
          const headers = new HttpHeaders().set(
            'Cache-Expire',
            expireTime.toString()
          );
          const response = stateEvent.clone({ headers, status: 304 });
          this.cache.set(request.urlWithParams, {
            expireTime,
            response,
          });
        }
      }),
      share()
    );
  }
}
