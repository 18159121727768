<mat-expansion-panel
  class="expansion-panel"
  hideToggle="true"
  #clientDetail
  *ngIf="clientOrder || transaction">
  <mat-expansion-panel-header class="expansion-header">
    <mat-panel-title class="expansion-title">
      {{ 'orderDetail.clientDetails' | translate }}
    </mat-panel-title>
    <mat-panel-description class="expansion-description">
      <mat-icon *ngIf="!clientDetail.expanded">add</mat-icon>
      <mat-icon *ngIf="clientDetail.expanded">remove</mat-icon>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <div class="container">
    <div class="client-info-row">
      <div class="client-info">
        <div class="client-info-text-wrapper">
          <span>{{ 'orderDetail.clientName' | translate }}:&nbsp;</span>
          <span class="dark-text" data-dd-action-name="Client Name" data-dd-privacy="mask">{{ name }}</span>
        </div>
      </div>
      <div *ngIf="clientOrder && (clientOrder.order.type === 'ECOMMORDER' || clientOrder.order.type === 'RETAILORDER')" class="client-info">
        <span>{{ 'orderDetail.clientAddress' | translate }}:&nbsp;</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{ billingStreetAddress }}</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          clientOrder.order.billingAddress?.cityName +
            ', ' +
            clientOrder.order.billingAddress?.provinceCode +
            ' ' +
            clientOrder.order.billingAddress?.postalCode
        }}</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          clientOrder.order.billingAddress?.countryCode
        }}</span>
      </div>
    </div>
    <div class="client-info-row">
      <div class="client-info">
        <span>{{ 'orderDetail.contactInformation' | translate }}:&nbsp;</span>
        <span class="dark-text" data-dd-action-name="Client Phone" data-dd-privacy="mask">{{ phone }}</span>
        <span class="dark-text" data-dd-action-name="Client Email" data-dd-privacy="mask">{{ email }}</span>
      </div>
      <div *ngIf="clientOrder && (clientOrder.order.type === 'ECOMMORDER' || clientOrder.order.type === 'RETAILORDER')" class="client-info">
        <span>{{ 'orderDetail.shipToAddress' | translate }}:&nbsp;</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{ shippingStreetAddress }}</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          clientOrder.order.shippingAddress?.cityName +
            ', ' +
            clientOrder.order.shippingAddress?.provinceCode +
            ' ' +
            clientOrder.order.shippingAddress?.postalCode
        }}</span>
        <span class="dark-text" data-dd-action-name="Client Address" data-dd-privacy="mask">{{
          clientOrder.order.shippingAddress?.countryCode
        }}</span>
      </div>
    </div>
  </div>
</mat-expansion-panel>
