<div class="container">
  <div class="banner-container">
    <banner
      [showSearchButton]="showSearchButton"
      (backButtonEmitter)="backButtonClicked()"
      (searchButtonEmitter)="searchButtonClicked()"
    ></banner>
  </div>
  <div class="auth-btns-container">

  </div>
  <router-outlet></router-outlet>
</div>
<loading-spinner></loading-spinner>
<popup-modal
  *ngIf="showGeneralError"
  [message]="'errors.general' | translate"
></popup-modal>

