import { EnvironmentBase } from "./environment.base";

export class Environment extends EnvironmentBase {
  constructor() {
    super("config/environment.dev.json");
  }

  public get azureClientId() {
    return process.env.NG_APP_CTT_DEV_AZURE_CLIENT_ID || super.azureClientId;
  } 

  public get azureRedirectUri() {
    return (
      super.azureRedirectUri || "https://concierge-transaction-dev.aritzia.com"
    );
  }
}

export const environment = new Environment();
