import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Transaction } from "../models/transaction.model";
import { ErrorService } from "./error.service";
import { ITransactionResponse } from "./transaction.service";

@Injectable({
  providedIn: 'root',
})
export class ArticleService {
  private _articleTransactions: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>([]);
  public articleTransactions$: Observable<Transaction[]> = this._articleTransactions.asObservable();
  public articleTransactions: Transaction[] = [];
  private _next: string | undefined;
  private _prev: string | undefined;
  private _transactionTotal = 0;
  public articleId = '';
  public articleListYAxis = 0;
  private _host = environment.apigee.host;
  constructor(
    private _httpClient: HttpClient,
    private _errorService: ErrorService
  ) {}

  public initTransactionsByArticle(articleId: string, storeId: string, articleDate: string):  Observable<Transaction[]>{
    const url = `${this._host}/transactions/?filter[genericId]=${articleId}&filter[location]=${storeId}&filter[date]=${articleDate}&page[size]=10`;
    this.articleId = articleId;
    return this._getTransactionsByArticle(url);
  }

  public getNextArticleTransactions(): Observable<Transaction[]> {
    if (this._next) {
      return this._getTransactionsByArticle(this._next);
    } else {
      return of([]);
    }
  }

  public getPrevArticleTransactions(): Observable<Transaction[]> {
    if (this._prev) {
      return this._getTransactionsByArticle(this._prev);
    } else {
      return of([]);
    }
  }

  private _getTransactionsByArticle(url: string): Observable<Transaction[]>{
    return this._httpClient.get<ITransactionResponse>(url).pipe(
      map((result: ITransactionResponse): Transaction[] => {
        if (result.data.length === 0) {
          throw new Error('No transactions found');
        }
        const transactions = result.data.map((val) => new Transaction(val));
        this._articleTransactions.next(transactions);
        this._next = result.links.next;
        this._prev = result.links.prev;
        this.articleTransactions =
          this.articleTransactions.length > 0 ? this.articleTransactions.concat(transactions) : transactions;
        this._transactionTotal = result.meta.estimatedTransactionsTotal;
        return transactions;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No transactions found') {
          this._errorService.showError('errors.noTransaction');
        } else {
          this._errorService.showGeneralError(true);
        }
        return of([]);
      })
    );
  }

  public resetArticleTransactions(): void {
    this.articleTransactions = [];
    this._articleTransactions.next([]);
    this._prev = undefined;
    this._next = undefined;
    this.articleId = '';
    this._transactionTotal = 0;
    this.articleListYAxis = 0;
  }

  public get articleTransactionTotal(): number {
    return this._transactionTotal;
  }
}