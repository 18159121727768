import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { concatMap, skip } from "rxjs/operators";
import { Transaction } from '../../models/transaction.model';
import { OrderResolver } from '../../resolvers/order.resolver';
import { CacheInterceptor } from '../../services/cache-interceptor.service';
import { CDCService } from '../../services/cdc.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { OrderService } from '../../services/order.service';
import { TransactionService } from '../../services/transaction.service';
import { IDictionary } from '../../shared/models/dictionary.model';

@Component({
  selector: 'order-detail-page',
  templateUrl: './order-detail-page.component.html',
  styleUrls: ['./order-detail-page.component.scss'],
})
export class OrderDetailPageComponent implements OnInit, OnDestroy {
  public clientOrder: IDictionary | undefined;
  public loggedIn$: Observable<boolean>;
  public transactions: Transaction[] = [];
  private _subscriptions = new Subscription();

  constructor(
    public msAuthService: MsAuthService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cdcService: CDCService,
    private _cacheInterceptor: CacheInterceptor,
    private _orderResolver: OrderResolver,
    private _orderService: OrderService,
    private _transactionService: TransactionService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._transactionService.transactions$.subscribe((val) => this.transactions = val)
    );
    
    this.loggedIn$ = this.msAuthService.loggedIn$;
    this._subscriptions.add(
        this.msAuthService.loggedIn$
            .pipe(
                skip(1),
                concatMap((..._) => {
                  this._cacheInterceptor.clearCache();
                  this._orderService.resetOrders();
                  return this._orderResolver.resolve(this._route.snapshot);
                })
            )
            .subscribe({
              next: (_) => this._fetchResolvedOrder(),
            })
    );

    this._fetchResolvedOrder();
  }

  private _fetchResolvedOrder() {
    const order = this._route.snapshot.data.order;
    if (order && order.length > 0) {
      if (order.length > 1) {
        this._router.navigateByUrl('/order-search');
      }
      if (order.length === 1) {
        const client = this._cdcService.clients.find((client) =>
          client.ids.some((id) => id === order[0].clientId)
        );
        if (this.msAuthService.isLoggedIn() && !client) {
          this._subscriptions.add(
              this._cdcService.clients$.subscribe((clients) => {
              if (clients) {
                this.clientOrder = {
                  client: clients[0],
                  order: order[0],
                };
              }
            })
          );
        } else {
          this.clientOrder = {
            client: client,
            order: order[0],
          };
        }
      }
    } else {
      this._router.navigateByUrl('/client-search');
    }
    window.scroll(0,0);
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
