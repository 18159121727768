import { Component, Input } from '@angular/core';
import { Transaction } from '../../models/transaction.model';
import { IDictionary } from '../../shared/models/dictionary.model';

@Component({
  selector: 'client-detail-expansion',
  templateUrl: './client-detail-expansion.component.html',
  styleUrls: ['./client-detail-expansion.component.scss'],
})
export class ClientDetailExpansionComponent {
  @Input() clientOrder: IDictionary | undefined;
  @Input() transaction: Transaction | undefined;

  public get clientId(): string {
    if (this.clientOrder) {
      return this.clientOrder.order.clientId;
    } else if(this.transaction) {
      return this.transaction.clientId;
    } else {
      return '';
    }
  }
  public get name(): string {
    if (this.clientOrder) {
      return this.clientOrder.client
      ? `${this.clientOrder.client.firstName} ${this.clientOrder.client.lastName}`
      : `${this.clientOrder.order.firstName} ${this.clientOrder.order.lastName}`;
    } else if(this.transaction) {
      return this.transaction.name;
    } else {
      return '';
    }
  }
  public get phone(): string {
    if (this.clientOrder) {
      return this.clientOrder.client
      ? this.clientOrder.client.phone
      : this.clientOrder.order.phone;
    } else if(this.transaction) {
      return this.transaction.phone;
    } else {
      return '';
    }
  }

  public get email(): string {
    if (this.clientOrder) {
      return this.clientOrder.client
      ? this.clientOrder.client.email
      : this.clientOrder.order.email;
    } else if(this.transaction) {
      return this.transaction.email;
    } else {
      return '';
    }
  }

  public get billingStreetAddress(): string {
    if (this.clientOrder && this.clientOrder.order.billingAddress) {
      const billing = this.clientOrder.order.billingAddress;
      if (billing.line2 !== '' && billing.line3 !== '')
        return `${billing.line1}, ${billing.line2}, ${billing.line3}`;
      else if (billing.line2 !== '')
        return `${billing.line1}, ${billing.line2}`;
      else return billing.line1;
    }
    return '';
  }

  public get shippingStreetAddress(): string {
    if (this.clientOrder && this.clientOrder.order.shippingAddress) {
      const shipping = this.clientOrder.order.shippingAddress;
      if (shipping.line2 !== '' && shipping.line3 !== '')
        return `${shipping.line1}, ${shipping.line2}, ${shipping.line3}`;
      else if (shipping.line2 !== '')
        return `${shipping.line1}, ${shipping.line2}`;
      else return shipping.line1;
    }
    return '';
  }
}
