import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Order } from '../../models/order.model';
import { Transaction } from '../../models/transaction.model';
import { ITransactionPayment } from '../../services/transaction.service';

@Component({
  selector: 'order-detail-summary',
  templateUrl: './order-detail-summary.component.html',
  styleUrls: ['./order-detail-summary.component.scss'],
})
export class OrderDetailSummaryComponent {
  @Input() order: Order | undefined;
  @Input() transaction: Transaction | undefined;
  constructor(private _translateService: TranslateService){}
  public get originalPrice(): number {
    if(this.order) {
      return this.order.originalPrice;
    } else if (this.transaction) {
      return this.transaction.originalPrice;
    } else {
      return null;
    }
  }
  public get discount(): number {
    if(this.order) {
      return this.order.discount;
    } else if (this.transaction) {
      return this.transaction.discount;
    } else {
      return null;
    }
  }
  public get shippingFee(): number {
    if(this.order) {
      return this.order.shippingFee;
    } else if (this.transaction) {
      return this.transaction.shippingFee;
    } else {
      return null;
    }
  }
  public get subtotal(): number {
    if(this.order) {
      return this.order.subtotal;
    } else if (this.transaction) {
      return this.transaction.subtotal;
    } else {
      return null;
    }
  }
  public get tax(): number {
    if(this.order) {
      return this.order.tax;
    } else if (this.transaction) {
      return this.transaction.tax;
    } else {
      return null;
    }
  }
  public get total(): number {
    if(this.order) {
      return this.order.total;
    } else if (this.transaction) {
      return this.transaction.total;
    } else {
      return null;
    }
  }
  public get currency(): string {
    if(this.order) {
      return this.order.currency;
    } else if (this.transaction) {
      return this.transaction.currency;
    } else {
      return '';
    }
  }

  public paymentTypeName(payment: ITransactionPayment): string {
    if(payment.typeName === "Cash Rounded") {
      return this._translateService.instant('orderDetail.cash');
    } else if(payment.typeName === "Balancing Remainder") {
      return this._translateService.instant('orderDetail.balanceDue');;
    } else if (payment.typeName === "Layaway Payments"){
      return this._translateService.instant('orderDetail.layawayPayments');;
    } else if (payment.typeName === "Gift Card"){
      return this._translateService.instant('orderDetail.giftCard');;
    } else if (payment.typeName === "Merch Return Card"){
      return this._translateService.instant('orderDetail.merchReturnCard');;
    } else if (payment.typeName === "Staff Voucher"){
      return this._translateService.instant('orderDetail.staffVoucher');;
    } else {
      return payment.typeName;
    }
  }

  public creditCardInfo(payment: ITransactionPayment): string {
    let cardNum = payment.enhancements.find(x => x.key === 'CARDNUM')?.value;
    return cardNum ? cardNum : '';
  }

  public cashAmountName(payment: ITransactionPayment): string {
    if(payment.typeName === "Cash") {
      if (payment.amount >= 0) {
        return this._translateService.instant('orderDetail.amountPaid');
      } else {
        return this._translateService.instant('orderDetail.changeOrRefundAmount');
      }
    } else if(payment.typeName === "Cash Rounded") {
      return this._translateService.instant('orderDetail.cashRounded');
    } else {
      return this._translateService.instant('orderDetail.amount');
    }
  }
}
