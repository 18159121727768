import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  private _showError: Subject<string> = new Subject<string>();
  public showError$: Observable<string> = this._showError.asObservable();

  private _showGeneralError: Subject<boolean> = new Subject<boolean>();
  public showGeneralError$: Observable<boolean> =
    this._showGeneralError.asObservable();

  constructor(private _translateService: TranslateService) {}

  public showError(error: string): void {
    const val = this._translateService.instant(error);
    this._showError.next(val);
  }

  public showGeneralError(val: boolean): void {
    this._showGeneralError.next(val);
  }
}
