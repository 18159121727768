import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, filter, mergeMap, switchMap, take} from 'rxjs/operators';
import { MsAuthService } from './ms-auth.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private tokenReadySubject = new BehaviorSubject<any>(null);
  constructor(
    private _tokenService: TokenService, // skipcq
    private _msAuthService: MsAuthService // skipcq
  ) { }

  intercept(
      request: HttpRequest<any>, // skipcq
      next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // skipcq
    if (request.url === '/assets/i18n/en.json') {
        // handles loading the language assest
        return next.handle(request);
    }
    if (request.url === '/assets/i18n/fr.json') {
        // handles loading the language assest
        return next.handle(request);
    }
    if (request.headers.get('skip')) {
      // Handles token request
      request = request.clone({
        headers: request.headers.delete('skip', 'true'),
      });
      return next.handle(request);
    }

    return this._msAuthService.silentToken$.pipe(
        filter(result => result !== null),
        take(1),
        mergeMap((res) => {
          const token = this._tokenService.token;
          if (!token) {
            if (!this.refreshTokenInProgress) {
              this.refreshTokenInProgress = true;
              this.tokenReadySubject.next(null);
              return this._tokenService.initialize().pipe(
                  switchMap((token) => {
                    this.refreshTokenInProgress = false;
                    this.tokenReadySubject.next(token);
                    return this._injectCredentials(request, next);
                  })
              )
            } else {
              return this.tokenReadySubject.pipe(
                  filter(result => result !== null),
                  take(1),
                  switchMap((res) => {
                    return this._injectCredentials(request, next);
                  })
              );
            }
          } else {
            return this._injectCredentials(request, next);
          }
        })
    );
  }
  private _injectCredentials(request: HttpRequest<any>, next: HttpHandler) {
    request = request.clone({
      withCredentials: true
    });
    return next.handle(request).pipe(
        catchError((err) => {
          return throwError(err);
        })
    );
  }
}
