import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';

export class RouteReuse implements RouteReuseStrategy {
  private cleanSession = true;

  handlers: { [key: string]: DetachedRouteHandle } = {};

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const shouldDetach =
      (route.data.shouldReuseRoute && this.cleanSession) || false;
    if (this.cleanSession) {
      this.cleanSession = false;
    }

    return shouldDetach;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (route.data.shouldReuseRoute) {
      this.handlers[route.routeConfig.path] = handle;
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!route.routeConfig && !!this.handlers[route.routeConfig.path];
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    if (!route.routeConfig) return null;
    return this.handlers[route.routeConfig.path];
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    if (curr.routeConfig) {
      if (
        curr.routeConfig.path === '' ||
        curr.routeConfig.path === '/' ||
        curr.routeConfig.path === 'client-search'
      ) {
        this.cleanSession = true;
      }
    }
    return (curr.routeConfig == future.routeConfig) || false;
  }
}
