import { ISalesChannelAvailability } from '../services/availability.service';
import { Store } from './store.model';

/*
Using variant, from IVariantGroup we will do a call for availability.
After grabbing availability we want to pipe the result,
pluck just the quantity and store id,
compare the store id to the stores we loaded,
and we should have all the information we need to make the Availability object.
*/
export class Availability {
  private _store: Store;
  private _quantity: number;
  private _productId: string;
  private _availableChannels: ISalesChannelAvailability[];

  constructor(
    quantity: number,
    productId: string,
    store: Store,
    availableChannels: ISalesChannelAvailability[]
  ) {
    this._productId = productId;
    this._store = store;
    this._quantity = quantity;
    this._availableChannels = availableChannels;
  }

  public get productId(): string {
    return this._productId;
  }

  public get storeName(): string {
    return this._store.name;
  }

  public get storeId(): string {
    return this._store.id;
  }

  public get quantity(): number {
    return this._quantity;
  }

  public get store(): Store {
    return this._store;
  }

  public get availableChannels(): ISalesChannelAvailability[] {
    return this._availableChannels;
  }
}
