<mat-form-field appearance="outline" [formGroup]="formGroup" class="date-range-picker-form-field" >
  <mat-label>{{label}}</mat-label>
  <mat-date-range-input [rangePicker]="rangePicker" >
    <input matStartDate placeholder="Start date" formControlName="start" [errorStateMatcher]="matcher" autocomplete="off">
    <input matEndDate placeholder="End date" formControlName="end" [errorStateMatcher]="matcher" autocomplete="off">
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
  <mat-date-range-picker color="primary" #rangePicker></mat-date-range-picker>
  <mat-error *ngIf="this.formGroup.controls['start'].hasError('matDatepickerParse')">
    {{'shared.dateRangePicker.invalidStartDateFormat' | translate}}
  </mat-error>
  <mat-error *ngIf="this.formGroup.hasError('startDateError') && !this.formGroup.controls['start'].hasError('matDatepickerParse')">
    {{'shared.dateRangePicker.missingStartDate' | translate}}
  </mat-error>

  <mat-error *ngIf="this.formGroup.controls['end'].hasError('matDatepickerParse')">
    {{'shared.dateRangePicker.invalidEndDateFormat' | translate}}
  </mat-error>

  <mat-error *ngIf="this.formGroup.hasError('endDateError') && !this.formGroup.controls['end'].hasError('matDatepickerParse')">
    {{'shared.dateRangePicker.missingEndDate' | translate}}
  </mat-error>

  <mat-error *ngIf="this.formGroup.hasError('bothDateError')">
    {{'shared.dateRangePicker.missingDateRange' | translate}}
  </mat-error>
</mat-form-field>