<div class="info-container">
  <div class="order-info" *ngIf="order && order.type === 'ECOMMORDER'">
    <div class="text-wrapper">
      <span>{{ original ? ('orderDetail.orderNumber' | translate): ('orderDetail.returnNumber' | translate)}}:&nbsp;</span>
      <span class="text">{{ order.saleChId !== '' ? order.saleChId : order.orderId }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.date' | translate }}:&nbsp;</span>
      <span class="text">{{ order.orderDate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.orderType' | translate }}:&nbsp;</span>
      <span class="text">{{ order.orderType | translate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.orderStatus' | translate }}:&nbsp;</span>
      <span class="text">{{ order.status | translate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.serviceLevel' | translate }}:&nbsp;</span>
      <span class="text">{{ order.serviceLevel | translate }}</span>
    </div>
  </div>

  <div class="order-info" *ngIf="order && (order.type === 'RETAILORDER' || isRetailTransactionReturn())">
    <div class="text-wrapper">
      <span>{{ original ? ('orderDetail.orderNumber' | translate): ('orderDetail.returnNumber' | translate )}}:&nbsp;</span>
      <span class="text">{{ order.saleChId !== '' ? order.saleChId : order.orderId }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.date' | translate }}:&nbsp;</span>
      <span class="text">{{ order.orderDate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.timestamp' | translate }}:&nbsp;</span>
      <span class="text">{{ order.orderTimestamp }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.orderType' | translate }}:&nbsp;</span>
      <span class="text">{{ order.orderType | translate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.orderStatus' | translate }}:&nbsp;</span>
      <span class="text">{{ order.status | translate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.serviceLevel' | translate }}:&nbsp;</span>
      <span class="text">{{ order.serviceLevel | translate }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.initiatingLocation' | translate }}:&nbsp;</span>
      <span class="text">{{ order.initiatingStore }}</span>
    </div>
    <div class="text-wrapper">
      <span>{{ 'orderDetail.pickupLocation' | translate }}:&nbsp;</span>
      <span class="text">{{ order.pickupStore }}</span>
    </div>
  </div>

  <div *ngIf="order && (order.type === 'ECOMMORDER' || isRetailTransactionReturn())" class="order-info">
    <order-detail-summary [order]="order"></order-detail-summary>
  </div>
</div>
<mat-expansion-panel
  class="expansion-panel"
  [expanded]="true"
  hideToggle="true"
  #clientDetail
  *ngIf="(order && order.type === 'ECOMMORDER' && order.returnCount > 0)">
    <mat-expansion-panel-header class="expansion-header">
      <mat-panel-title class="expansion-title">
        {{ 'orderDetail.returns' | translate }}
      </mat-panel-title>
      <mat-panel-description class="expansion-description">
        <mat-icon *ngIf="!clientDetail.expanded">add</mat-icon>
        <mat-icon *ngIf="clientDetail.expanded">remove</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="object-separator" *ngFor="let returnOrder of order.returnOrders">
      <order-detail [order]="returnOrder" [original]="false"></order-detail>
    </div>
</mat-expansion-panel>
<div *ngIf="original && order && order.type === 'ECOMMORDER'" class="title">{{'orderDetail.originalItemDetails' | translate}}</div>
<div *ngIf="order && (order.type !== 'RETAILTRANSACTION' || isRetailTransactionReturn())" [ngClass]="{'item-container': !original}">
    <order-detail-item
      *ngFor="let item of order.items; let i = index"
      [item]="item"
      [order]="order"
      [index]="i"
      [original]="original">
    </order-detail-item>
</div>

