import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from 'rxjs/operators';
import { environment } from "../../environments/environment";
import { Transaction } from "../models/transaction.model";
import { ErrorService } from "./error.service";

export interface ITransactionResponse {
  data: ITransactionData[];
  meta?: ITransactionMeta;
  links?: ITransactionLinks;
}

export interface ITransactionLinks {
  self: string;
  next?: string;
  prev?: string;
}

export interface ITransactionMeta {
  estimatedTransactionsTotal?: number;
  include?: string;
  pageSize?: number;
  pageBefore?: string;
  pageAfter?: string;
  coorelationId?: string;
}

export interface ITransactionData {
  type: string;
  id: string;
  attributes: ITransactionAttributes;
  relationships?: ITransactionRelationships;
}

export interface ITransactionRelationships {
  layaways?: ITransactionRelationship;
  returns?: ITransactionRelationship;
  specialOrders?: ITransactionRelationship;
}

export interface ITransactionRelationship {
  data: {                              
    type: string;
    id: string;
    locationId: string;
    registerId: string;
  }[]
}

export interface ITransactionAttributes {
  typeCode: string;
  typeName: string;
  typeGroupCode: string;
  typeGroupName: string;
  postingDate: string;
  creationTimestamp: string;
  startTimestamp: string;
  endTimestamp: string;
  locationId: string;
  locationName: string;
  registerId: string;
  cashierId: string;
  localCurrency: string;
  currency: string;
  customerId: string;
  customer: ITransactionCustomer[];
  salesItems: ITransactionSaleItem[];
  payments: ITransactionPayment[];
}

export interface ITransactionCustomer {
  key: string;
  value: string;
}

export interface ITransactionSaleItem {
  itemId: string;
  typeCode: string;
  typeName: string;
  reasonCode: string;
  reasonName: string;
  productId: string;
  genericId: string;
  colorName: string;
  sizeName: string;
  upc: string;
  serialId: string;
  quantity: number;
  unit: string;
  initialPrice: number;
  salesPrice: number;
  merchandiseCategoryCode: string;
  merchandiseCategoryName: string;
  productName: string;
  typeGroupCode: string;
  typeGroupName: string;
  references: ITransactionReference;
  commissions: ITransactionCommission[];
  taxes: ITransactionTax[];
  discounts: ITransactionDiscount[];
  enhancements: ITransactionEnhancement[];
}

export interface ITransactionReference {
  id: string;
  locationId: string;
  locationName: string;
  registerId: string;
  itemId: string;
}

export interface ITransactionCommission {
  itemId: string;
  associateId: string;
  amount: number;
}

export interface ITransactionTax {
  itemId: string;
  typeCode: string;
  typeName: string;
  amount: number;
  enhancements: ITransactionEnhancement[];
}

export interface ITransactionDiscount {
  itemId: string;
  typeCode: string;
  typeName: string;
  reasonCode: string;
  reasonName: string;
  amount: number;
  accountId: string;
  id: string;
  idQualifier: string;
  bonusBuyId: string;
  offerId: string;
  enhancements: ITransactionEnhancement[];
}

export interface ITransactionEnhancement {
  group: string;
  key: string;
  value: string;
}

export interface ITransactionPayment {
  itemId: string;
  typeCode: string;
  typeName: string;
  amount: number;
  currency: string;
  tenderId: string;
  referenceId: string;
  enhancements: ITransactionEnhancement[];
}

@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  private _transactions: BehaviorSubject<Transaction[]> = new BehaviorSubject<Transaction[]>([]);
  public transactions$: Observable<Transaction[]> = this._transactions.asObservable();
  public transactions: Transaction[] = [];
  private _host = environment.apigee.host;
  constructor(
    private _httpClient: HttpClient,
    private _errorService: ErrorService
  ) {}

  public getTransactionByOrderId(orderId: string): Observable<Transaction[]> {
    const url = `${this._host}/transactions/?filter[referenceId]=${orderId}`;
    return this._httpClient.get<ITransactionResponse>(url).pipe(
      map((result: ITransactionResponse): Transaction[] => {
        if (result.data.length === 0) {
          throw new Error('No transactions found');
        }
        const transactions = result.data.map((val) => new Transaction(val));
        this._transactions.next(transactions);
        this.transactions = transactions;
        return transactions;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No transactions found') {
          this._errorService.showError('errors.noTransaction');
        } else {
          this._errorService.showGeneralError(true);
        }
        throw e;
      })
    );
  }

  public getTransactionById(transactionId: string, registerId: string, locationId: string, transactionDate: string): Observable<Transaction[]> {
    const url = `${this._host}/transactions/?filter[referenceId]=${transactionId}&filter[location]=${locationId}&filter[registerId]=${registerId}&filter[date]=${transactionDate}`;
    return this._httpClient.get<ITransactionResponse>(url).pipe(
      map((result: ITransactionResponse): Transaction[] => {
        const transactions = result.data.map((val) => new Transaction(val));
        this._transactions.next(transactions);
        this.transactions = transactions;
        return transactions;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No transactions found') {
          this._errorService.showError('errors.noTransaction');
        } else {
          this._errorService.showGeneralError(true);
        }
        throw e;
      })
    );
  }

  public resetTransaction(): void {
    this.transactions = [];
    this._transactions.next([]);

  }
}