import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { Order } from '../models/order.model';
import { IDictionary } from "../shared/models/dictionary.model";
import { CDCService } from "./cdc.service";
import { ErrorService } from './error.service';
import { LoadingService } from "./loading.service";
import { MsAuthService } from './ms-auth.service';
import { TransactionService } from './transaction.service';

export interface IOrderResponse {
  data: IOrderData[];
  links: {
    self: string;
    next?: string;
    prev?: string;
  };
  meta: {
    estimatedOrdersTotal: number;
    include: string;
    pageSize?: number;
    correlationId: string;
  };
  included?: IIncluded[];
}

export interface IOrderData {
  type: string;
  id: string;
  attributes: IOrderAttributes;
  relationships?: IRelationships;
  hasReturns?: boolean;
  transactionType?: string;
  transactionRefId?: string;
}

export interface IOrderAttributes {
  orderTypeCode: string;
  orderTypeName: string;
  orderDate: string;
  orderTimestamp: string;
  salesChannelOrderId: string;
  salesChannelId: string;
  locationId: string;
  locationName: string;
  siteSourceId: string;
  siteSourceName: string;
  orderFulfillmentStatusCode: string;
  orderFulfillmentStatusName: string;
  registerId: string;
  associateId: string;
  createdById: string;
  createdByEmail: string;
  customerId: string;
  customerReferenceId: string;
  productCount: number;
  subtotal: number;
  tax: number;
  total: number;
  currency: string;
  deliveryBlock: string;
  shippingConditionCode: string;
  shippingConditionName: string;
  holdUser: string;
  holdTimestamp: string;
  orderProducts: IOrderProduct[];
  addresses?: IOrderAddress[];
  payments: any[];
  relationships?: IRelationships;
  saleAssociateName?: string;
  cashierId?: string;
  cashierName?: string;
}

export interface IPayment {
  type: string;
  id: string;
  attributes: IPaymentData;
}

export interface IPaymentData {
  paymentType: string;
  paymentAmount: number;
  paymentCardHolder: string;
  paymentCardNumber: string;
  paypalAttributes: IPaypalAttributes;
}

export interface IPaypalAttributes {
  paypalEmail: string,
  paypalRequestId: string;
  paypalRequestToken: string;
  paypalAuthTransactionId: string;
  paypalOSRequestId: string;
  paypalOSRequestToken: string;
  paypalOSTransactionId: string;
}

export interface IRelationships {
  returns: IRelationship;
  shipments: IRelationship;
}

export interface IRelationship {
  data: IRelationshipData[];
}

export interface IRelationshipData {
  type: string;
  id: string;
}

export interface IOrderAddress {
  addressTypeCode: string;
  addressTypeName: string;
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  line3: string;
  cityName: string;
  provinceCode: string;
  postalCode: string;
  countryCode: string;
  phoneNumber: string;
  email: string;
}

export interface IOrderProduct {
  lineItem: string;
  lineItemTypeGroup: string;
  productId: string;
  genericId: string;
  brandName: string;
  productName: string;
  colorName: string;
  sizeName: string;
  upc: string;
  alternativeProductId: string;
  merchandiseCategoryCode: string;
  merchandiseCategoryName: string;
  articleAssignmentCode: string;
  articleAssignmentName: string;
  quantity: number;
  unit: string;
  svcNumber?: number;
  initialPrice: number;
  salesPrice: number;
  discountTotal: number;
  subtotal: number;
  tax: number;
  taxes: ITax[];
  total: number;
  referenceDocumentNumber: string;
  referenceDocumentLine: string;
  lineItemStatusCode: string;
  lineItemStatusName: string;
  returnableQuantity: number;
  returnableQuantities: any[];
  returnReasonCode: string;
  returnReasonName: string;
  returnNotes: string;
  fulfillmentLocationId: string;
  pickupLocationId: string;
  processedQuantity: number;
  processedDate: string;
  backOrderFlag: boolean;
  backOrderDate: string;
  specialServiceCode: string;
  reserveFlag: boolean;
  fulfillmentStatus: IFulfillmentStatus[];
}

export interface ITax {
  taxTypeCode: string;
  taxTypeName: string;
  taxAmount: number;
}

export interface IFulfillmentStatus {
  statusCode: string;
  statusName: string;
  fulfillmentLocationId: string;
  sequence: number;
  rejectionReasonCode?: string;
  userId: string;
  timestamp: string;
}

export interface IIncluded {
  type: string;
  id: string;
  attributes: IIncludeShipmentAttributes | IOrderAttributes;
}

export interface IIncludeShipmentAttributes {
  shipmentOrderId: string;
  shipDate: string;
  shippingPointCode: string;
  shippingPointName: string;
  carrierCode: string;
  carrierName: string;
  serviceLevelCode: string;
  shipmentProducts: IShipmentProduct[];
}

export interface IShipmentProduct {
  lineItem: string;
  orderLineItem: string;
  quantity: number;
  unit: string;
  trackingNumbers: ITrackingNumber[];
}

export interface ITrackingNumber {
  trackingItemLineItem: string;
  trackingItemTypeCode: string;
  trackingItemTypeName: string;
  trackingIdentifierId: string;
  trackingNumber: string;
}

export interface IOrder {
  firstName: string; //cdc
  lastName: string; //cdc
  email: string; //cdc
  phone: string; //cdc
  status: string;
  orderId: string;
  orderDate: string;
  totalCount: number;
  clientId?: string;
  billingAddress: IOrderAddress;
  shippingAddress: IOrderAddress;
  originalPrice: string;
  discount: string;
  subtotal: string;
  tax: string;
  shippingFee: string;
  total: string;
  items: IItem[];
}

export interface IItem {
  name: string;
  id: string;
  color: string;
  size: string;
  quantity: number;
  priceList: number;
  price: number;
  trackingNumber: string;
  fulfillingStore: string;
  status: string;
  upc: string;
  history: IHistory[];
}

export interface IHistory {
  date: string;
  location: string;
  user: string;
  status: string;
  reason?: string;
}

export enum TransactionSelections {
  all = "ALL",
  ecomm = "ECOMM",
  retail = "RETAIL"
}

export interface IProductIdBanner {
  productId: string;
  banner: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private _host = environment.apigee.host;
  private _orders: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  public orders$: Observable<Order[]> = this._orders.asObservable();
  private _clientOrders: BehaviorSubject<IDictionary[]> = new BehaviorSubject<IDictionary[]>([]);
  public clientOrders$: Observable<IDictionary[]> = this._clientOrders.asObservable();

  public orders: Order[] = [];
  public transactionType: TransactionSelections = TransactionSelections.all; 
  public orderListYAxis = 0;
  private _customerIds: string[] = [];
  public dateRange = new FormGroup({start: new FormControl, end: new FormControl});
  
  private _next: string | undefined;
  private _prev: string | undefined;
  // skipcq
  constructor(
    private _httpClient: HttpClient,
    private _transactionService: TransactionService,
    private _errorService: ErrorService,
    private _msAuthService: MsAuthService,
    private _cdcService: CDCService,
    private _loadingService: LoadingService
  ) {}

  public getOrderById(id: string): Observable<Order[] | null> {
    let url = `${this._host}/orders/${id}?include=returns,shipments&fulfillmentstatushistory=true`;
    return this.getOrders(url);
  }

  public getInitialOrdersByCustomerIds(ids: string[]): Observable<Order[]> {
    let url = '';
    if (this.dateRange) {
      url = `${this._host}/orders?filter[customerId]=${ids.join(
        ','
      )}&filter[orderDate][ge]=${
          this.dateRange.value.start.toISOString().split('T')[0]
      }&filter[orderDate][le]=${this.dateRange.value.end.toISOString().split('T')[0]
          }&page[size]=10&include=returns,shipments&fulfillmentstatushistory=true`;
    } else {
      url = `${this._host}/orders?filter[customerId]=${ids.join(
        ','
      )}&page[size]=10&include=returns,shipments&fulfillmentstatushistory=true`;
    }
    return this.getOrders(url);
  }

  public getNextOrders(): Observable<Order[]> {
    if (this._next) {
      return this.getOrders(this._next+'&include=returns,shipments&fulfillmentstatushistory=true');
    } else {
      return of([]);
    }
  }

  // Not sure when we will use this
  public getPrevOrders(): Observable<Order[]> {
    if (this._prev) {
      return this.getOrders(this._prev+'&include=returns,shipments&fulfillmentstatushistory=true');
    } else {
      return of([]);
    }
  }

  private getOrders(url: string): Observable<Order[]> {
    return this._httpClient.get<IOrderResponse>(url).pipe(
      mergeMap((orders: IOrderResponse) : Observable<IOrderResponse> => {
        if (orders.data.length === 0) {
          throw new Error('No orders found');
        }
        const obs = orders.data.map((order) => {
          const type = this._calculateType(order);
          if(type === 'RETAILORDER') {
            return this._transactionService.getTransactionByOrderId(order.attributes.salesChannelOrderId).pipe(
              map((transactions) => {
                let hasReturns = false;
                let transactionType = '';
                let transactionRefId = '';
                transactions.forEach((transaction) => {
                  if(transaction.items.some((item) =>
                  item.statusId === '2801' ||
                  item.statusId === '2802' ||
                  item.statusId === '2803' ||
                  item.statusId === '2804')) {
                    hasReturns = true;
                  }
                });
                return {
                  ...order,
                  hasReturns: hasReturns,
                  transactionType: transactionType,
                  transactionRefId: transactionRefId
                };
              })
            );
          } else if (type === 'RETAILTRANSACTION') {
            return this._transactionService.getTransactionById(order.id, order.attributes.registerId, order.attributes.locationId, order.attributes.orderDate.replace(/-/g, '')).pipe(
              map((transactions) => {
                let hasReturns = false;
                let transactionType = '';
                let transactionRefId = '';
                transactions.forEach((transaction) => {
                  if(transaction.items.some((item) =>
                  item.statusId === '2801' ||
                  item.statusId === '2802' ||
                  item.statusId === '2803' ||
                  item.statusId === '2804')) {
                    hasReturns = true;
                  }
                  let specialType = transaction.items.find((item) =>
                  item.referenceId.charAt(0).toLowerCase() === 'l' ||
                  item.referenceId.charAt(0).toLowerCase() === 's' ||
                  item.referenceId.charAt(0).toLowerCase() === 'h'
                  );
                  if(specialType) {
                    if(specialType.referenceId.charAt(0).toLowerCase() === 'l') {
                      transactionType = 'Layaway';
                      transactionRefId = specialType.referenceId;
                    } else if(specialType.referenceId.charAt(0).toLowerCase() === 's') {
                      transactionType = 'Special Order';
                      transactionRefId = specialType.referenceId;
                    } else if(specialType.referenceId.charAt(0).toLowerCase() === 'h') {
                      transactionType = 'Sales Delivery';
                      transactionRefId = specialType.referenceId;
                    }
                  }
                });
                return {
                  ...order,
                  hasReturns: hasReturns,
                  transactionType: transactionType,
                  transactionRefId: transactionRefId
                };
              })
            );
          } else {
            return of(order);
          }
        });
        return forkJoin(obs).pipe(
          map((val) => {
            return {
              ...orders,
              data: val
            }
          })
        )
      }),
      map((result: IOrderResponse): Order[] => {
        const orders = result.data.map(
          (order) =>
            new Order(order, result.meta.estimatedOrdersTotal, result.included, order.hasReturns)
        );
        this._next = result.links.next;
        this._prev = result.links.prev;
        this.orders =
          this.orders.length > 0 ? this.orders.concat(orders) : orders;
        this._orders.next(this.orders);
        return orders;
      }),
      catchError((e) => {
        console.log(e);
        if (e.message === 'No orders found') {
          this._errorService.showError('errors.ordersNoOrders');
        } else {
          this._errorService.showGeneralError(true);
        }
        this._loadingService.stopLoading();
        throw e;
      })
    );
  }

  public reassignStore$(orderId: string, lineItem: string, locationId: string): Observable<any> { 
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const url = `${this._host}/orders`;
    const uuid = uuidv4()
    const body = {
      data: [
        {
          type: "orders",
          id: uuid,
          attributes: {
            salesChannelOrderId: orderId,
            holdUser: this._msAuthService.getName(),
            orderProducts: [
              {
                lineItem: lineItem,
                fulfillmentStatus: [
                  {
                      locationId: locationId
                  }
                ]
              }
            ]
          }
        }
      ]
    }
    return this._httpClient.patch(url, JSON.stringify(body), {headers: headers}).pipe(
      catchError((e) => {
        console.log(e);
        this._errorService.showGeneralError(true);
        throw e;
      })
    );
  }

  public resetOrders(): void {
    this._orders.next([]);
    this.orders = [];
    this._prev = undefined;
    this._next = undefined;
    this.dateRange = undefined;
    this.orderListYAxis = 0;
  }

  public get customerIds() {
    return this._customerIds;
  }

  public set customerIds(ids: string[]) {
    this._customerIds = ids;
  }

  private _calculateType(order: IOrderData): string {
    if (order.attributes.salesChannelId === 'RETAIL' && order.attributes.salesChannelOrderId) {
      return "RETAILORDER";
    } else if (order.attributes.salesChannelId === 'RETAIL') {
      return "RETAILTRANSACTION";
    } else {
      return "ECOMMORDER";
    }
  }

  public setClientOrder() {
    const clientOrders = this.orders.map((order) => {
          const client = this._cdcService.clients.find((client) =>
              client.ids.some((id) => id === order.clientId)
          );
          const dict: IDictionary = {
            client: client,
            order: order,
          };
          return dict;
        }
    );
    this._clientOrders.next(clientOrders)
  }
}
