<ng-container *ngIf="dataStream$ | async as transactionList">
  <div class="title-container">
    <span class="title">{{
      'articleSearchList.client' | translate
    }}</span>
    <span class="title">{{
      'articleSearchList.transaction' | translate
    }}</span>
    <span class="title">{{
      'articleSearchList.itemColour' | translate
    }}</span>
    <span class="title">{{
      'articleSearchList.itemSize' | translate
    }}</span>
    <span class="title">{{ 'articleSearchList.date' | translate }}</span>
  </div>
  <cdk-virtual-scroll-viewport
    *ngIf="this.currentArray.length > 0"
    [itemSize]="100"
    (scrolledIndexChange)="nextBatch()">
    <li *cdkVirtualFor="let transaction of transactionList; let index = index;">
      <mat-card
        class="transaction-container"
        (click)="transactionClicked(transaction)">
        <div class="info-container">
          <div class="info">
            <span class="name text">{{ transaction.name }}</span>
            <span class="text">{{ transaction.phone }}</span>
            <div class="ellipsis-text-box">
              <span class="ellipsis-text">{{ transaction.email }}</span>
            </div>
          </div>
          <div class="info">
            <span class="text">{{'articleSearchList.retail' | translate}}</span>
            <span class="text">{{'articleSearchList.transaction' | translate}}</span>
            <span class="text">{{ transaction.id }}</span>
          </div>
          <div class="info">
            <span class="text" *ngFor="let item of getItemColour(transaction)">{{item}}</span>
          </div>
          <div class="info">
            <span class="text" *ngFor="let item of getItemSize(transaction)">{{item}}</span>
          </div>
          <div class="info">
            <span class="text">{{ transaction.transactionDate }}</span>
          </div>
        </div>
      </mat-card>
    </li>
    <div class="spinner" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </cdk-virtual-scroll-viewport>
</ng-container>
<div class="spinner" *ngIf="loading && currentArray.length === 0">
  <mat-spinner></mat-spinner>
</div>
