import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Store } from '../models/store.model';
import { IDictionary } from '../shared/models/dictionary.model';
import { ErrorService } from './error.service';

export interface IStoreResponse {
  data: IStore[];
}

export interface IStore {
  type?: string;
  id: string;
  attributes: IStoreAttributes;
}

export interface IStoreAttributes {
  address: IAddress;
  category: string;
  name: string;
  banner?: string;
  phone?: string;
  email?: string;
  hours?: {
    day: string;
    open: string;
    close: string;
  }[];
  timeZone?: string;
  currency?: string;
  taxJurisdiction?: string;
  districtCode?: string;
  districtName?: string;
  regionCode?: string;
  regionName?: string;
  geolocation?: IGeolocation;
  salesChannelsEnabled?: boolean;
  blockReasonCode?: string;
  blockReasonName?: string;
}

export interface IAddress {
  line1: string;
  line2: string;
  city: string;
  provinceCode: string;
  provinceName: string;
  postal: string;
  countryCode: string;
}

export interface IGeolocation {
  longitude?: number;
  latitude?: number;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private _host = environment.apigee.host;
  private _storesSubject: BehaviorSubject<Store[]> = new BehaviorSubject<
    Store[]
  >([]);
  public stores$: Observable<Store[]> = this._storesSubject.asObservable();
  public stores: Store[] = [];

  constructor(
    private _httpClient: HttpClient, // skipcq
    private _errorService: ErrorService // skipcq
  ) {}

  public getStores(): Observable<Store[]> {
    const url = this._host + '/locations';

    // Loads store from cache data if data is less than a day old
    const resetTime = localStorage.getItem('storeResetTime');

    if (resetTime) {
      const timer = new Date(resetTime);
      timer.setHours(timer.getHours() + 24);
      const cachedStores = localStorage.getItem('stores');
      if (new Date().getTime() < timer.getTime() && cachedStores) {
        this.stores = JSON.parse(cachedStores).map(
          (store: IStore) => new Store(store)
        );
        this._storesSubject.next(this.stores);
        return of(this.stores);
      }
    }

    return this._httpClient.get<IStoreResponse>(url).pipe(
      map((response: IStoreResponse) => {
        const result = response.data.filter(
          (store) => !store.attributes.blockReasonCode
        );
        const stores = result.map((storeResponse) => new Store(storeResponse));
        this._storesSubject.next(stores);
        this.stores = stores;
        localStorage.setItem('stores', JSON.stringify(result));
        localStorage.setItem('storeResetTime', new Date().toString());
        return stores;
      }),
      catchError((e) => {
        this._errorService.showGeneralError(true);
        throw e;
      })
    );
  }

  public generateStoreList(stores: Store[]): IDictionary {
    const dictionary: IDictionary = {};
    dictionary['DC'] = [];
    stores.forEach((store: Store) => {
      if (store.category === 'Distribution') {
        dictionary['DC'].push({
          key: store.id,
          value: store.id,
          selected: false,
        });
      } else {
        const index = Object.keys(dictionary).findIndex(
          (key) => key === store.regionName
        );
        if (index > -1) {
          dictionary[store.regionName].push({
            key: store.id,
            value: store.name,
            selected: false,
          });
        } else {
          dictionary[store.regionName] = [
            { key: store.id, value: store.name, selected: false },
          ];
        }
      }
    });
    return dictionary;
  }

  // public generateSortedStoreList(stores: Store[]): IDataPair[] {
  //   const arr: ILocationDistance[] = [];
  //   if (this.defaultStore) {
  //     stores.forEach((store: Store) => {
  //       const distance = this.getDistance(this.defaultStore!, store);

  //       // If missing longitude or latitude, just make it max distance.
  //       if (store.id === this.defaultStore!.id)
  //         arr.push({ key: store.id, value: store.name, distance: 0 });
  //       else if (distance)
  //         arr.push({ key: store.id, value: store.name, distance: distance });
  //       else
  //         arr.push({
  //           key: store.id,
  //           value: store.name,
  //           distance: Number.MAX_SAFE_INTEGER,
  //         });
  //     });
  //   } else {
  //     stores.forEach((store: Store) => {
  //       arr.push({
  //         key: store.id,
  //         value: store.name,
  //         distance: Number.MAX_SAFE_INTEGER,
  //       });
  //     });
  //   }

  //   arr.sort((a, b) => a.distance - b.distance);
  //   return arr.map((a) => {
  //     return { key: a.key, value: a.value };
  //   });
  // }

  // private getDistance(a: Store, b: Store): number | null {
  //   if (a.longitude && a.latitude && b.longitude && b.latitude) {
  //     //Haversine formula
  //     const deltaLatitude = this.degreeToRadius(
  //       parseFloat(a.latitude) - parseFloat(b.latitude)
  //     );
  //     const deltaLongitude = this.degreeToRadius(
  //       parseFloat(a.longitude) - parseFloat(b.longitude)
  //     );
  //     const x =
  //       Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
  //       Math.cos(this.degreeToRadius(parseFloat(a.latitude))) *
  //         Math.cos(this.degreeToRadius(parseFloat(b.latitude))) *
  //         Math.sin(deltaLongitude / 2) *
  //         Math.sin(deltaLongitude / 2);
  //     return 2 * Math.atan2(Math.sqrt(x), Math.sqrt(1 - x)) * 6371;
  //   } else {
  //     return null;
  //   }
  // }

  // private degreeToRadius(val: number): number {
  //   return val * (Math.PI / 180);
  // }
}
