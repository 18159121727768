<div class="container">
  <mat-card class="message">
    <p>{{ message }}</p>
    <button
      mat-raised-button
      color="primary"
      (click)="closeGeneralError()"
      class="button"
    >
      {{ 'popup.okay' | translate }}
    </button>
  </mat-card>
</div>
