import { Component, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Client } from '../../models/client.model';
import { ArticleService } from '../../services/article.service';
import { CDCService, IClientSearchParams } from '../../services/cdc.service';
import { ErrorService } from '../../services/error.service';
import { MsAuthService } from '../../services/ms-auth.service';
import { OrderService, TransactionSelections } from '../../services/order.service';
import { ScannerService } from '../../services/scanner.service';
import { TransactionService } from '../../services/transaction.service';
import { IDataPair } from '../../shared/models/data-pair.model';

@Component({
  selector: 'client-search-page',
  templateUrl: './client-search-page.component.html',
  styleUrls: ['./client-search-page.component.scss'],
})
export class ClientSearchPageComponent implements OnDestroy {
  public clientForm: FormGroup;
  public transactionForm: FormGroup;
  public articleForm: FormGroup;

  // Order Id FormControls
  public orderIdControl: FormControl<string>;

  // Transaction Id FormControls
  public transactionIdControl: FormControl<string>;
  public registerIdControl: FormControl<string>;
  public storeIdControl: FormControl<string>;
  public transactionDateControl: FormControl<Date>;

  // Article Id FormControls
  public itemNumberControl: FormControl<string>;
  public articleStoreIdControl: FormControl<string>;
  public articleDateControl: FormControl<Date>;

  // Search FormControls
  public firstNameControl: FormControl<string>;
  public lastNameControl: FormControl<string>;
  public phoneNumberControl: FormControl<string>;
  public emailControl: FormControl<string>;
  public dateRangeControl: FormGroup;
  public transactionControl: FormControl<IDataPair>;
  public transactionTypes: IDataPair[] = [];

  private _subscriptions = new Subscription();
  private _scannerSub = new Subscription();
  @ViewChild('orderInput') orderIdInput;

  constructor(
    public msAuthService: MsAuthService,
    private _router: Router,
    private _orderService: OrderService,
    private _clientService: CDCService,
    private _articleService: ArticleService,
    private _errorService: ErrorService,
    private _translateService: TranslateService,
    private _transactionService: TransactionService,
    private _scannerService: ScannerService, // skipcq
    private _route: ActivatedRoute,
  ) {
    let queryParams = this._route.snapshot.queryParams;
    // Sets Transaction Form
    this.transactionForm = new FormGroup({
      transactionId: new FormControl(''),
      registerId: new FormControl(''),
      storeId: new FormControl(''),
      transactionDate: new FormControl(null)
    }, this._transactionIdValidator());

    this.articleForm=  new FormGroup({
      articleId: new FormControl(''),
      storeId: new FormControl(''),
      articleDate: new FormControl(null)
    }, this._articleValidator());

    // Sets whole client search form
    this.clientForm = new FormGroup({
      orderId: new FormControl(
        ''
      ),
      firstName: new FormControl(''),
      lastName: new FormControl(''),
      phoneNumber: new FormControl('', Validators.pattern('^[\\d() +-]+$')),
      email: new FormControl('', Validators.email),
      dateRange: new FormGroup({
        start: new FormControl(new Date(new Date().setDate(new Date().getDate() - 30))),
        end: new FormControl(new Date())},[this._dateRangeValidator()]),
      transaction: new FormControl(),
      article: new FormControl()
    });

    //Sets transaction types and translation
    this._setTransactionTypes();
    this._subscriptions.add(this._translateService.onLangChange.subscribe((val) => {
      this._setTransactionTypes();
    }));

    // Links all the controls with the forms
    this.orderIdControl = this.clientForm.controls['orderId'] as FormControl;
    this.transactionIdControl = this.transactionForm.controls['transactionId'] as FormControl;
    this.registerIdControl = this.transactionForm.controls['registerId'] as FormControl;
    this.storeIdControl = this.transactionForm.controls['storeId'] as FormControl;
    this.transactionDateControl = this.transactionForm.controls['transactionDate'] as FormControl;
    this.itemNumberControl = this.articleForm.controls['articleId'] as FormControl;
    this.articleStoreIdControl = this.articleForm.controls['storeId'] as FormControl;
    this.articleDateControl = this.articleForm.controls['articleDate'] as FormControl;
    this.firstNameControl = this.clientForm.controls[
      'firstName'
    ] as FormControl;
    this.lastNameControl = this.clientForm.controls['lastName'] as FormControl;
    this.phoneNumberControl = this.clientForm.controls[
      'phoneNumber'
    ] as FormControl;
    this.emailControl = this.clientForm.controls['email'] as FormControl;
    this.dateRangeControl = this.clientForm.controls['dateRange'] as FormGroup;
    this.transactionControl = this.clientForm.controls['transaction'] as FormControl;
    this.transactionControl.patchValue(this.transactionTypes[0]);
    if(queryParams){
      if(queryParams['email']){
        this.emailControl.patchValue(queryParams['email'])
      }
      if(queryParams['phone']){
        this.phoneNumberControl.patchValue(queryParams['email'])
      }
      if(queryParams['start'] && queryParams['end']){
        this.dateRangeControl.patchValue({start: new Date(queryParams['start']), end: new Date(queryParams['end'])})
      }
    }
    
    this.enableScanner();

    // Listens for changes in all field and enables and disables them accordingly
    this._subscriptions.add(
      this.orderIdControl.valueChanges.subscribe((val) => {
        if (val && val !== '') {
          this.firstNameControl.disable({emitEvent: false});
          this.lastNameControl.disable({emitEvent: false});
          this.phoneNumberControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.dateRangeControl.disable({emitEvent: false});
          this.transactionControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else {
          this._enableSearchFields();
        }
      })
    );

    this._subscriptions.add(
      this.transactionIdControl.valueChanges.subscribe((val) => {
        if (val && val !== ''){
          this.firstNameControl.disable({emitEvent: false});
          this.lastNameControl.disable({emitEvent: false});
          this.phoneNumberControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.dateRangeControl.disable({emitEvent: false});
          this.transactionControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.storeIdControl.enable({emitEvent: false});
          this.registerIdControl.enable({emitEvent: false});
          this.transactionDateControl.enable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else {
          this._enableSearchFields();
          this.registerIdControl.setValue('');
          this.storeIdControl.setValue('');
          this.transactionDateControl.setValue(null);
          this.storeIdControl.disable({emitEvent: false});
          this.registerIdControl.disable({emitEvent: false});
          this.transactionDateControl.disable({emitEvent: false});
        } 
      })
    );

    this._subscriptions.add(
      this.itemNumberControl.valueChanges.subscribe((val) => {
        if (val && val !== ''){
          this.firstNameControl.disable({emitEvent: false});
          this.lastNameControl.disable({emitEvent: false});
          this.phoneNumberControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.dateRangeControl.disable({emitEvent: false});
          this.transactionControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.articleStoreIdControl.enable({emitEvent: false});
          this.articleDateControl.enable({emitEvent: false});
        } else {
          this._enableSearchFields();
          this.articleStoreIdControl.setValue('');
          this.articleDateControl.setValue(null);
          this.articleStoreIdControl.disable({emitEvent: false});
          this.articleDateControl.disable({emitEvent: false});
        } 
      })
    );

    this._subscriptions.add(
      this.firstNameControl.valueChanges.subscribe((val) => {
        if (val && val !== '') {
          this.phoneNumberControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else if (this.firstNameControl.value === '' && this.lastNameControl.value === ''){
          this._enableSearchFields();
        }
      })
    );

    this._subscriptions.add(
      this.lastNameControl.valueChanges.subscribe((val) => {
        if (val && val !== '') {
          this.phoneNumberControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else if (this.firstNameControl.value === '' && this.lastNameControl.value === ''){
          this._enableSearchFields();
        }
      })
    );

    this._subscriptions.add(
      this.phoneNumberControl.valueChanges.subscribe((val) => {
        if (val && val !== '') {
          this.firstNameControl.disable({emitEvent: false});
          this.lastNameControl.disable({emitEvent: false});
          this.emailControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else {
          this._enableSearchFields();
        }
      })
    );

    this._subscriptions.add(
      this.emailControl.valueChanges.subscribe((val) => {
        if (val && val !== '') {
          this.firstNameControl.disable({emitEvent: false});
          this.lastNameControl.disable({emitEvent: false});
          this.phoneNumberControl.disable({emitEvent: false});
          this.transactionIdControl.disable({emitEvent: false});
          this.orderIdControl.disable({emitEvent: false});
          this.itemNumberControl.disable({emitEvent: false});
        } else {
          this._enableSearchFields();
        }
      })
    );

    this._subscriptions.add(
        this.msAuthService.loggedIn$.subscribe((loggedIn) => {
          if (loggedIn) {
            this._enableSearchFields();
            this.storeIdControl.disable({emitEvent: false});
            this.registerIdControl.disable({emitEvent: false});
            this.transactionDateControl.disable({emitEvent: false});
            this.articleStoreIdControl.disable({emitEvent: false});
            this.articleDateControl.disable({emitEvent: false});
          } else {
            this._resetSearchFields();
            this.storeIdControl.disable({emitEvent: false});
            this.registerIdControl.disable({emitEvent: false});
            this.transactionDateControl.disable({emitEvent: false});
            this.firstNameControl.disable({emitEvent: false});
            this.lastNameControl.disable({emitEvent: false});
            this.phoneNumberControl.disable({emitEvent: false});
            this.emailControl.disable({emitEvent: false});
            this.dateRangeControl.disable({emitEvent: false});
            this.transactionControl.disable({emitEvent: false});
            this.transactionIdControl.disable({emitEvent: false});
            this.orderIdControl.disable({emitEvent: false});
            this.itemNumberControl.disable({emitEvent: false});
            this.articleStoreIdControl.disable({emitEvent: false});
            this.articleDateControl.disable({emitEvent: false});
          }
        })
    );
  }
  
  private _enableSearchFields() {
    this.firstNameControl.enable({emitEvent: false});
    this.lastNameControl.enable({emitEvent: false});
    this.phoneNumberControl.enable({emitEvent: false});
    this.emailControl.enable({emitEvent: false});
    this.dateRangeControl.enable({emitEvent: false});
    this.transactionControl.enable({emitEvent: false});
    this.transactionIdControl.enable({emitEvent: false});
    this.orderIdControl.enable({emitEvent: false});
    this.itemNumberControl.enable({emitEvent: false});
  }


  private _resetSearchFields() {
    this.firstNameControl.setValue('');
    this.lastNameControl.setValue('');
    this.phoneNumberControl.setValue('');
    this.emailControl.setValue('');
    this.registerIdControl.setValue('');
    this.storeIdControl.setValue('');
    this.transactionDateControl.setValue(null);
    this.transactionIdControl.setValue('');
    this.orderIdControl.setValue('');
    this.itemNumberControl.setValue('');
    this.articleStoreIdControl.setValue('');
    this.articleDateControl.setValue(null);
  }

  public ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._scannerSub.unsubscribe();
  }

  public disableScanner(): void {
    this._scannerSub.unsubscribe();
  }

  public enableScanner(): void {
    const scannerSub = this._scannerService.scanDetected$.subscribe(
      (entry: string) => {
        this.orderIdControl.patchValue(entry);
        this.orderIdInput.blur();
        this.searchClicked();
      }
    );

    this._scannerSub.add(scannerSub);
  }

  public get searchDisabled(): boolean {
    return !(
      (this.orderIdControl.value.trim() !== '') ||
      (this.transactionIdControl.value.trim() !== '' && this.storeIdControl.value.trim() !== '' && this.registerIdControl.value.trim() !== '' && this.transactionDateControl.value !== null) ||
      (this.articleStoreIdControl.value !== '' &&
      this.itemNumberControl.value !== '' &&
      this.articleDateControl.value !== null &&
      !this.articleForm.hasError('error')) ||
      (this.firstNameControl.value.trim() !== '' &&
        this.lastNameControl.value.trim() !== '' && this.dateRangeControl.valid) ||
      this.emailControl.value.trim() !== '' ||
      (this.phoneNumberControl.value.trim() !== '' && this.phoneNumberControl.valid)
    );
  }

  public searchClicked(): void {
    this._transactionService.resetTransaction();
    this._orderService.resetOrders();
    this._articleService.resetArticleTransactions();

    // Handles search by order id
    if (this.orderIdControl.value !== '') {
      if(this.isSpecialRetailTransaction(this.orderIdControl.value)) {
        this._router.navigateByUrl(`/transaction/order/${this.orderIdControl.value}`);
      } else {
        this._router.navigateByUrl(`/order/${this.orderIdControl.value}`);
      }

    // Handles search by transaction id
    } else if(this.transactionIdControl.value !== '' &&
      this.storeIdControl.value !== '' &&
      this.registerIdControl.value !== '' &&
      this.transactionDateControl.value !== null) {
        this._orderService.resetOrders();
        const trnDate = this.transactionDateControl.value.toISOString().slice(0,10).replace(/-/g,"");
        this._router.navigateByUrl(`/transaction/${this.transactionIdControl.value}/${this.registerIdControl.value}/${this.storeIdControl.value}/${trnDate}`);
    
    // Handles search by article
    } else if(this.articleStoreIdControl.value !== '' &&
    this.itemNumberControl.value !== '' &&
    this.articleDateControl.value !== null) {
      this._orderService.resetOrders();
        const articleDate = this.articleDateControl.value.toISOString().slice(0,10).replace(/-/g,"");
        this._router.navigateByUrl(`/article/${this.itemNumberControl.value}/${this.articleStoreIdControl.value}/${articleDate}`);
    
    // Handles search by client
    } else if (
      (this.firstNameControl.value.trim() !== '' &&
        this.lastNameControl.value.trim() !== '') ||
      this.emailControl.value.trim() !== '' ||
      this.phoneNumberControl.value.trim() !== ''
    ) {
      this._orderService.dateRange = this.dateRangeControl;
      this._orderService.transactionType = this.transactionControl.value.key;
      const query = this.captureClientSearch();
      this._subscriptions.add(
        this._clientService
          .clientSearch(query)
          .subscribe((result: Client[]) => {
            if (result.length > 0) {
              let customerIds;
              switch(this.transactionControl.value.key) {
                case TransactionSelections.ecomm:
                  customerIds = result.reduce((arr, res) => {
                    arr.push(...res.sfccIds);
                    return arr;
                  }, <string[]>[]);
                  this._orderService.customerIds = customerIds;
                  break;
                case TransactionSelections.retail:
                  customerIds = result.reduce((arr, res) => {
                    arr.push(...res.retailIds);
                    return arr;
                  }, <string[]>[]);
                  this._orderService.customerIds = customerIds;
                  break;
                default:
                  customerIds = result.reduce((arr, res) => {
                    arr.push(...res.ids);
                    return arr;
                  }, <string[]>[]);
                  this._orderService.customerIds = customerIds;
                  break;
              }
              this._router.navigateByUrl(`/order-search`);
            } else {
              this._errorService.showError('errors.noCustomerFound');
            }
          })
      );
    }
  }

  private isSpecialRetailTransaction(val: string): boolean {
    const orderNumberFormat = new RegExp(/([A-Za-z]{1}\d{7}$)/gm);
    return !orderNumberFormat.test(val) && (val.charAt(0).toLowerCase() === 'l' ||
        val.charAt(0).toLowerCase() === 'h' ||
        val.charAt(0).toLowerCase() === 's')
  }

  private captureClientSearch(): IClientSearchParams {
    const searchQuery: IClientSearchParams = { fields: ['data', 'profile'] };

    if (this.firstNameControl.value !== '') {
      searchQuery.firstName = this.firstNameControl.value.trim();
    }

    if (this.lastNameControl.value !== '') {
      searchQuery.lastName = this.lastNameControl.value.trim();
    }

    if (this.phoneNumberControl.value !== '') {
      searchQuery.phoneNumber = this.phoneNumberControl.value.trim();
    }

    if (this.emailControl.value !== '') {
      searchQuery.email = this.emailControl.value.trim();
    }

    if (this.dateRangeControl.valid) {
      searchQuery.transactionsSince = this.dateRangeControl.value.start.toISOString().split('T')[0]
    }

    return searchQuery;
  }

  private _transactionIdValidator(): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.transactionIdControl && this.transactionIdControl.value.length < 1) {
        return null;
      }
      let errorToggled = false;
      let error = '';
      if(this.registerIdControl && this.registerIdControl.value.length < 1) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.registerId')+', ';
      }
      if(this.storeIdControl && this.storeIdControl.value.length < 1) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.storeId')+', ';
      }
      if(this.transactionDateControl && this.transactionDateControl.value === null) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.transactionDate')+', ';
      }

      if(errorToggled) {
        error = error.slice(0,-2);
        return {error: this._translateService.instant('clientSearch.followingFieldsMissing')+': ' + error + '.'} as ValidationErrors;
      } else {
        return null;
      }
    };
  }

  private _articleValidator(): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.itemNumberControl && this.itemNumberControl.value.length < 1 &&
        this.articleStoreIdControl && this.articleStoreIdControl.value.length < 1 &&
        this.articleDateControl && this.articleDateControl.value === null) {
        return null;
      }
      let errorToggled = false;
      let error = '';
      if(this.itemNumberControl && this.itemNumberControl.value.length < 5) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.itemNumberInvalid')+', ';
      }
      if(this.articleStoreIdControl && this.articleStoreIdControl.value.length < 1) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.storeId')+', ';
      }
      if(this.articleDateControl && this.articleDateControl.value === null) {
        errorToggled = true;
        error = error + this._translateService.instant('clientSearch.transactionDate')+', ';
      }

      if(errorToggled) {
        error = error.slice(0,-2);
        return {error: this._translateService.instant('clientSearch.followingFieldsMissing')+': ' + error + '.'} as ValidationErrors;
      } else {
        return null;
      }
    };
  }

  private _dateRangeValidator(): ValidatorFn | null {
    return (control: AbstractControl): ValidationErrors | null => {
      if(!control.value.start && !control.value.end){
        return {bothDateError: this._translateService.instant('clientSearch.invalidMissingDate')} as ValidationErrors;
      }else if(control.value.start === null){
        return {startDateError: this._translateService.instant('clientSearch.startDateInvalidMissing')} as ValidationErrors;
      }else if(control.value.end === null){
        return {endDateError: this._translateService.instant('clientSearch.endDateInvalidMissing')} as ValidationErrors;
      }else{
        return null;
      }
    };
  }

  private _setTransactionTypes(): void {
    this.transactionTypes = [{
      key: TransactionSelections.all,
      value: this._translateService.instant('clientSearch.all')
    },
    {
      key: TransactionSelections.ecomm,
      value: this._translateService.instant('clientSearch.ecomm')
    },
    {
      key: TransactionSelections.retail,
      value: this._translateService.instant('clientSearch.retail')
    }];
  }
}