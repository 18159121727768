import { IFulfillmentStatus, IOrderProduct } from '../services/order.service';

export class Item {
  private _name: string;
  private _brand: string;
  private _id: string;
  private _lineItem: string;
  private _productId: string;
  private _color: string;
  private _size: string;
  private _quantity: number;
  private _priceList: number;
  private _price: number;
  private _trackingNumbers: string[];
  private _fulfillingStore: string;
  private _backOrderDate: string;
  private _status: string;
  private _upc: string;
  private _history: IFulfillmentStatus[];
  private _subTotal: number;
  private _tax: number;
  private _total: number;
  private _returnReferenceLine: string;
  private _processedDate: string;
  private _backOrderFlag: boolean;
  private _merchandiseCategoryCode: string;


  public constructor(item: IOrderProduct, tracking: string[]) {
    this._name = item.productName;
    this._brand = item.brandName;
    this._id = item.genericId;
    this._lineItem = item.lineItem;
    this._productId = item.productId;
    this._color = item.colorName;
    this._size = item.sizeName;
    this._quantity = item.quantity;
    this._priceList = item.initialPrice;
    this._price = item.salesPrice;
    this._trackingNumbers = tracking;
    this._fulfillingStore = item.fulfillmentLocationId;
    this._backOrderDate =
      item.backOrderDate === '0000-00-00' ? '' : item.backOrderDate;
    this._status =
      item.fulfillmentStatus.length > 0
        ? this.calculateItemStatus(item.fulfillmentStatus[0].statusCode)
        : 'item.unknown';
    this._upc = item.upc;
    this._history = item.fulfillmentStatus.map((itemStatus) => {
      return {
        ...itemStatus,
        statusName: this.calculateItemStatus(itemStatus.statusCode),
      };
    });
    this._subTotal = item.subtotal;
    this._tax = item.tax;
    this._total = item.total;
    this._returnReferenceLine = item.referenceDocumentLine;
    this._processedDate = item.processedDate;
    this._backOrderFlag = item.backOrderFlag;
    this._merchandiseCategoryCode = item.merchandiseCategoryCode;
  }

  public get name(): string {
    return this._name;
  }
  public get brand(): string {
    return this._brand;
  }
  public get id(): string {
    return this._id;
  }
  public get lineItem(): string {
    return this._lineItem;
  }
  public get productId(): string {
    return this._productId;
  }
  public get color(): string {
    return this._color;
  }
  public get size(): string {
    return this._size;
  }
  public get quantity(): number {
    return this._quantity;
  }
  public get priceList(): number {
    return this._priceList;
  }
  public get price(): number {
    return this._price;
  }
  public get trackingNumbers(): string[] {
    return this._trackingNumbers;
  }
  public get fulfillingStore(): string {
    return this._fulfillingStore;
  }
  public get backOrderDate(): string {
    return this._backOrderDate;
  }
  public get status(): string {
    return this._status;
  }
  public get upc(): string {
    return this._upc;
  }
  public get returnReferenceLine(): string{
    return this._returnReferenceLine;
  }
  public get processedDate(): string{
    return this._processedDate;
  }
  public get subTotal(): number {
    return this._subTotal;
  }
  public get total(): number {
    return this._total;
  }
  public get tax(): number {
    return this._tax;
  }
  public get history(): IFulfillmentStatus[] {
    return this._history;
  }
  public get backOrderFlag(): boolean {
    return this._backOrderFlag;
  }
  public get merchandiseCategoryCode(): string {
    return this._merchandiseCategoryCode;
  }

  private calculateItemStatus(id: string): string {
    switch (id) {
      case '0':
        return 'item.unprocessed';
      case '1':
        return 'item.newOrder';
      case '2':
        return 'item.polled';
      case '3':
        return 'item.accepted';
      case '4':
        return 'item.rejected';
      case '5':
        return 'item.cancelled';
      case '6':
        return 'item.picked';
      case '7':
        return 'item.fulfilled';
      case '8':
        return 'item.unfulfilled';
      case '11':
        return 'item.intransit';
      case '12':
        return 'item.intransitPolled';
      case '13':
        return 'item.received';
      default:
        return 'item.unknown';
    }
  }
}
