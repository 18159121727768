<mat-form-field class="input" appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input
    #inputRef
    matInput
    autocomplete="{{ auto }}"
    type="{{ type }}"
    maxlength="{{maxLength}}"
    placeholder="{{ placeholder }}"
    [formControl]="formControl"
    (focus)="handleFocus()"
    (blur)="handleBlur()" />
  <button
    type="button"
    *ngIf="formControl.enabled && formControl.value !== ''"
    matSuffix
    mat-icon-button
    aria-label="Clear"
    (click)="formControl.setValue('')">
    <mat-icon>close</mat-icon>
  </button>
  <mat-error *ngIf="formControl.hasError">
    {{ error? error : formControl.errors?.error }}
  </mat-error>
</mat-form-field>
