<div class="container">
  <form [formGroup]="clientForm">
    <div class="search-title">{{'clientSearch.title' | translate}}</div>
    <input-field
      #orderInput
      class="full-input"
      [label]="'clientSearch.orderId'| translate"
      [formControl]="orderIdControl"
      [error]="'clientSearch.orderIdError' | translate"
      (onFocus)="disableScanner()"
      (onBlur)="enableScanner()"
      ngDefaultControl>
    </input-field>
    <div class="line"><span class="line-text">{{'clientSearch.or' | translate}}</span></div>
    <div class="search-title">{{'clientSearch.searchByTransaction' | translate}}</div>
    <form [formGroup]="transactionForm">
      <input-field
        class="full-input"
        [label]="'clientSearch.searchByTransactionId' | translate"
        [formControl]="transactionIdControl"
        (onFocus)="disableScanner()"
        (onBlur)="enableScanner()"
        ngDefaultControl>
      </input-field>
      <div class="half-container">
          <input-field
            class="half-input"
            [label]="'clientSearch.registerId' | translate"
            [formControl]="registerIdControl"
            [full]="false"
            (onFocus)="disableScanner()"
            (onBlur)="enableScanner()"
            ngDefaultControl>
          </input-field>

          <input-field
            class="half-input"
            [label]="'clientSearch.storeId' | translate"
            [formControl]="storeIdControl"
            [full]="false"
            (onFocus)="disableScanner()"
            (onBlur)="enableScanner()"
            ngDefaultControl>
          </input-field>
      </div>
      <mat-form-field appearance="outline" class="date-range-picker-form-field half-input">
        <mat-label>{{'clientSearch.transactionDateWithFormat' | translate}}</mat-label>
        <input matInput [formControl]="transactionDateControl" [matDatepicker]="transactionDatePicker" class="full-input">
        <mat-datepicker-toggle matSuffix [for]="transactionDatePicker"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #transactionDatePicker></mat-datepicker>
      </mat-form-field> 
      <mat-error class="transaction-error" *ngIf="transactionForm.hasError('error')">
        {{transactionForm.errors?.error}}
      </mat-error>
    </form>
    <div class="line"><span class="line-text">{{'clientSearch.or' | translate}}</span></div>
    <div class="search-title">{{'clientSearch.searchByClientInformation' | translate}}</div>
    <input-field
      class="full-input"
      [label]="'clientSearch.email' | translate"
      [formControl]="emailControl"
      [error]="'clientSearch.emailError' | translate"
      (onFocus)="disableScanner()"
      (onBlur)="enableScanner()"
      ngDefaultControl>
    </input-field>
    
    <input-field
      class="full-input"
      [label]="'clientSearch.phoneNumber' | translate"
      [formControl]="phoneNumberControl"
      [error]="'clientSearch.phoneNumberError' | translate"
      (onFocus)="disableScanner()"
      (onBlur)="enableScanner()"
      ngDefaultControl>
    </input-field>

    <div class="half-container">
      <input-field
        class="half-input"
        [label]="'clientSearch.firstName' | translate"
        [formControl]="firstNameControl"
        [full]="false"
        (onFocus)="disableScanner()"
        (onBlur)="enableScanner()"
        ngDefaultControl>
      </input-field>
      <span class="and-symbol">&</span>
      <input-field
        class="half-input"
        [label]="'clientSearch.lastName' | translate"
        [formControl]="lastNameControl"
        [full]="false"
        (onFocus)="disableScanner()"
        (onBlur)="enableScanner()"
        ngDefaultControl>
      </input-field>
    </div>
    <date-range-picker
      class="full-input"
      [label]="'clientSearch.dateRange' | translate"
      [formGroup]="dateRangeControl"
      ngDefaultControl>
    </date-range-picker>

    <drop-down 
      ngDefaultControl
      [label]="'clientSearch.transactionType' | translate"
      [data]="transactionTypes"
      [formControl]="transactionControl"
      [multiple]="false">
    </drop-down>
    <div class="line"><span class="line-text">{{'clientSearch.or' | translate}}</span></div>
    <div class="search-title">{{'clientSearch.searchForTransactionByItemNumber' | translate}}</div>
    <form [formGroup]="articleForm">
      <div class="half-container">
          <input-field
            class="half-input"
            [label]="'clientSearch.itemNumber' | translate"
            [formControl]="itemNumberControl"
            [full]="false"
            [maxLength]="'6'"
            (onFocus)="disableScanner()"
            (onBlur)="enableScanner()"
            ngDefaultControl>
          </input-field>

          <input-field
            class="half-input"
            [label]="'clientSearch.storeId' | translate"
            [formControl]="articleStoreIdControl"
            [full]="false"
            (onFocus)="disableScanner()"
            (onBlur)="enableScanner()"
            ngDefaultControl>
          </input-field>
      </div>
      <mat-form-field appearance="outline" class="date-range-picker-form-field half-input">
        <mat-label>{{'clientSearch.transactionDateWithFormat' | translate}}</mat-label>
        <input matInput [formControl]="articleDateControl" [matDatepicker]="articleDatePicker" class="full-input">
        <mat-datepicker-toggle matSuffix [for]="articleDatePicker"></mat-datepicker-toggle>
        <mat-datepicker color="primary" #articleDatePicker></mat-datepicker>
      </mat-form-field> 
      <mat-error class="transaction-error" *ngIf="articleForm.hasError('error')">
        {{articleForm.errors?.error}}
      </mat-error>
    </form>
    <div class="search-button-wrapper">
      <button
        mat-raised-button
        type="submit"
        class="search-button"
        color="primary"
        [disabled]="searchDisabled"
        (click)="searchClicked()">
        {{ 'clientSearch.search' | translate }}
      </button>
    </div>
  </form>
</div>