
import { Subject } from 'rxjs';

export interface IEnvironment {
  apigee: {
    host: string;
    tokenPath: string;
    sfcc: string;
    banner: string;
  };
  product: {
    shop: string;
    data: string;
  };
  cdc: {
    servicePath: string;
    uris: {
      accountSearch: string;
      accountGet: string;
      accountSet: string;
    };
  };
  availability: {
    servicePath: string;
    servicePathBatch: string;
  };
  azure: {
    redirectUri: string;
  };
  idle: {
    posInactive: number,
    posActive: number,
    zebraInactive: number,
    zebraActive: number,
    twilioInactive: number,
    twilioActive: number,
    inactive: number,
    active: number,
    timeout: number
  };
  dataDogEnv: string
}

export class EnvironmentBase implements IEnvironment {
  private _production = false;
  private _environment = <IEnvironment>{
    production: false,
    apigee: {
      host: 'https://api.qa.aritzia.com',
      tokenPath: '/oauth2/token',
      sfcc: 'external/sfcc',
      banner: 'Aritzia_CA',
    },
    product: {
      shop: 'shop/products',
      data: '-/data/products',
    },
    cdc: {
      servicePath: '/external/cdc',
      uris: {
        accountSearch: 'accounts.search',
        accountGet: 'accounts.getAccountInfo',
        accountSet: 'accounts.setAccountInfo',
      },
    },
    availability: {
      servicePath: 'products/:productCode/availability',
      servicePathBatch: 'products/availability',
    },
    azure: {
      redirectUri: null,
    },
    idle: {
      /*
        PHT stand-alone on POS timeout = 5 minutes of inactivity
        PHT embedded within MAO on POS = 5 minutes of inactivity
        PHT stand-alone on Zebra device = 1 hour if inactive, 8 hours if active
        PHT embedded within MAO on Zebra device = 1 hour if inactive, 8 hours if active
        PHT for concierge twilio = infinite for both inactive and active
        PHT for risk = 4 hours if inactive, infinite if active
        PHT for concierge outside of twilio = 4 hours if inactive, infinite if active
        PHT for everyone else = 4 hours if inactive, infinite if active
            
        Time is calculated in seconds. 0 means infinite
      */
      posInactive: 300,
      posActive: 28800,
      zebraInactive: 3600,
      zebraActive: 28800,
      twilioInactive: 0,
      twilioActive: 0,
      inactive: 14400,
      active: 0,
      timeout: 60
    },
    dataDogEnv: "qa"
  };

  private _basePath = 'config';
  protected _environmentLoadedStream = new Subject<IEnvironment>();
  public environmentLoaded$ = this._environmentLoadedStream.asObservable();

  constructor(protected _environmentFile: string) {
    this.initEnvironment()
        .then()
        .catch((err) => {
          console.error(err);
        });
  }

  public async initEnvironment(): Promise<void> {
    try {
      const config = await this.environmentLoad();
      if (config) {
        this._environment = config;
        this._environmentLoadedStream.next(config);
      }
    } catch (error) {
      throw new Error('Failed to load environment file');
    }
  }

  protected environmentLoad(): Promise<IEnvironment> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', this._environmentFile);
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText) as IEnvironment);
        } else {
          reject(new Error(`Failed to load environment file: ${xhr.status}`));
        }
      };
      xhr.send();
    });
  }

  public get production() {
    return this._production;
  }

  public set production(value: boolean) {
    this._production = value;
  }

  public get apigee() {
    return this._environment.apigee;
  }

  public get clientId(): string {
    return process.env.NG_APP_CLIENT_ID;
  }

  public get clientSecret(): string {
    return process.env.NG_APP_CLIENT_SECRET;
  }

  public get azureClientId(): string {
    return process.env.NG_APP_CTT_DEV_AZURE_CLIENT_ID;
  }

  public get azureRedirectUri(): string {
    if (this._environment) {
      return this._environment.azure.redirectUri;
    }
    return null;
  }

  public get product() {
    return this._environment.product;
  }

  public get cdc() {
    return this._environment.cdc;
  }

  public get availability() {
    return this._environment.availability;
  }

  public get azure() {
    return this._environment.azure;
  }

  public get idle() {
    return this._environment.idle;
  }

  public get dataDogEnv(){
    return this._environment.dataDogEnv
  }
}