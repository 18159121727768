import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Transaction } from '../../models/transaction.model';
import { ErrorService } from '../../services/error.service';
import { MsAuthService } from '../../services/ms-auth.service';

@Component({
  selector: 'transaction-detail-page',
  templateUrl: './transaction-detail-page.component.html',
  styleUrls: ['./transaction-detail-page.component.scss']
})
export class TransactionDetailPageComponent implements OnInit, OnDestroy {
  public transactions: Transaction[];
  private _subscriptions = new Subscription();
  constructor(
    private _route: ActivatedRoute,
    private _msAuthService: MsAuthService,
    private _router: Router,
    private _errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this._subscriptions.add(
        this._msAuthService.loggedIn$.subscribe((val) => {
          if(val) {
            this.transactions = this._route.snapshot.data.transactions;
            if(!(this.transactions && this.transactions.length > 0)) {
              this._errorService.showError('No transactions found.');
              this._router.navigateByUrl('/client-search');
            }
          } else {
            this._errorService.showError('errors.notLoggedIn');
            this._router.navigateByUrl(`/client-search`);
          }
        })
    );
    window.scroll(0,0);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
